<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="分类名称">
            <el-input
              v-model="listQuery.categoryName"
              clearable
              placeholder="请输入分类名称"
            />
          </el-form-item>
          <el-form-item label="发布状态">
            <el-select v-model="listQuery.putaway" clearable>
              <el-option
                v-for="item in putAwayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="置顶状态">
            <el-select v-model="listQuery.isTop" clearable>
              <el-option
                v-for="item in topList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <div class="addButton">
      <el-button
        icon="el-icon-plus"
        size="mini"
        type="primary"
        @click="handleAdd"
      >
        新增
      </el-button>
    </div>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          {{
            (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="类别名称"
        prop="categoryName"
        width="200"
      />
      <el-table-column align="center" label="发布状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.putaway">
            {{ format(scope.row.putaway, putAwayList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.putaway, putAwayList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="置顶状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isTop">
            {{ format(scope.row.isTop, topList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.isTop, topList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="置顶时间"
        prop="topTime"
        width="200"
      />
      <el-table-column align="center" label="备注" prop="remark" width="250" />
      <el-table-column align="center" label="排序" prop="seq" width="100" />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="250"
      />
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
          <el-button size="mini" type="text" @click="handleTop(scope.row.id)">
            {{ scope.row.isTop === 0 ? '置顶' : '取消置顶' }}
          </el-button>

          <el-button
            size="mini"
            type="text"
            @click="handlePutAway(scope.row.id)"
          >
            {{ scope.row.putaway === 0 ? '发布' : '取消发布' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="类别名称" prop="categoryName">
              <el-input
                v-model="formData.categoryName"
                clearable
                placeholder="请输入类别名称"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="发布状态" prop="putaway">
              <el-radio-group v-model="formData.putaway">
                <el-radio
                  v-for="item in putAwayList"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>

              <!-- <el-select
                v-model="formData.putaway"
                placeholder="请选择发布状态"
                style="width: 300px"
              >
                <el-option
                  v-for="item in putAwayList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </el-form-item>
            <el-form-item label="置顶状态" prop="isTop">
              <el-radio-group v-model="formData.isTop">
                <el-radio
                  v-for="item in topList"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>

              <!-- <el-select
                v-model="formData.isTop"
                placeholder="请选择发布状态"
                style="width: 300px"
              >
                <el-option
                  v-for="item in topList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="formData.remark"
                clearable
                placeholder="请输入备注"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="排序" prop="seq">
              <el-input-number
                v-model="formData.seq"
                clearable
                :min="1"
                placeholder="请输入排序"
                style="max-width: 300px"
              />
            </el-form-item>
            <div
              style="
                padding-left: 100px;
                margin-top: -10px;
                margin-bottom: 4px;
                font-size: 14px;
                color: #ccc;
              "
            >
              提示：按排序号数字升序排序，数字越小，排序越前
            </div>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import {
    articleCategoryList,
    deleteArticleCategory,
    addArticleCategory,
    editArticleCategory,
    toggleIsTop,
    togglePutAway,
  } from '@/api/article/articlecategory'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  export default {
    name: 'ArticleCategory',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          categoryName: '',
          putaway: '',
          isTop: '',
        },
        dailog: {
          height: 320,
          title: '文章分类',
          visible: false,
          width: '600px',
        },
        rules: {
          categoryName: [
            { required: true, message: '请输入文章分类名称', trigger: 'blur' },
          ],
          putaway: [
            { required: true, message: '请选择发布状态', trigger: 'blur' },
          ],
          isTop: [
            { required: true, message: '请选择置顶状态', trigger: 'blur' },
          ],
          seq: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        },
        formData: {},
        totalCount: 0,
        tableData: [],
        tableData1: [],
        putAwayList: [
          {
            label: '未发布',
            value: 0,
          },
          {
            label: '已发布',
            value: 1,
          },
        ],
        topList: [
          {
            label: '未置顶',
            value: 0,
          },
          {
            label: '已置顶',
            value: 1,
          },
        ],
        loading: false,
        // 格式化
        format(val, list) {
          const res = list.filter((item) => item.value === val)
          if (res.length > 0) {
            return res[0].label
          } else {
            return '未知'
          }
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await articleCategoryList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      // 新增
      handleAdd() {
        this.dailog.visible = true
        this.dailog.title = '新增文章分类'
      },
      // 删除
      handleDelete(id) {
        this.$confirm('是否删除该文章分类?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const ids = id
          deleteArticleCategory({ ids }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      //置顶
      handleTop(id) {
        this.$confirm('确认切换置顶状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        }).then(() => {
          toggleIsTop({ id }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      //发布状态
      handlePutAway(id) {
        this.$confirm('确认切换发布状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        }).then(() => {
          togglePutAway({ id }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      // 编辑
      handleEdit(row) {
        this.dailog.visible = true
        this.dailog.title = '编辑文章分类'
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      // 确定新增/修改
      handleConfirm() {
        this.$refs['formData'].validate((valid) => {
          console.log(valid)
          if (valid) {
            if (this.dailog.title === '新增文章分类') {
              addArticleCategory(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            } else {
              editArticleCategory(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            }
          }
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['formData'].resetFields()
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      reset() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          categoryName: '',
          putaway: '',
          isTop: '',
        }
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    padding: 0 !important;

    .addButton {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }

  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
