var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        title: _vm.rowData?.id ? "编辑/二维码海报图" : "新增/二维码海报图",
        visible: _vm.addVisible,
        width: "680px",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-width": "100px",
            model: _vm.ruleForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户端", prop: "client" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择用户端" },
                          model: {
                            value: _vm.ruleForm.client,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "client", $$v)
                            },
                            expression: "ruleForm.client",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "管理后台", value: "PLATFORM" },
                          }),
                          _c("el-option", {
                            attrs: { label: "房东端(Web)", value: "SHOP" },
                          }),
                          _c("el-option", {
                            attrs: { label: "租户端", value: "MEMBER" },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: "房东端(小程序)",
                              value: "SHOP_WECHAT_SMALL_ROUTINE",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "status", $$v)
                            },
                            expression: "ruleForm.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是", value: "ENABLED" },
                          }),
                          _c("el-option", {
                            attrs: { label: "否", value: "DISABLED" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "海报名称", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          placeholder: "请输入海报名称",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "title", $$v)
                          },
                          expression: "ruleForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "add-code-poster" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传/更换", prop: "imgUrl" } },
                    [
                      _vm.ruleForm.img != ""
                        ? _c("JMUpload", {
                            attrs: { multiple: false, number: 1 },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.ruleForm.img,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "img", $$v)
                              },
                              expression: "ruleForm.img",
                            },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "png-tip" }, [
                        _vm._v(
                          " 图片尺寸: 660x330PX，或者宽高比2:1， 图片大小请限制在10MB内 "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }