<template>
  <div class="content">
    <div class="main">
      <div class="basic">
        <el-page-header :content="'账单详情'" @back="goBack" />
        <div class="title">基本信息</div>
        <!-- <div :class="detail.payStatus == 0 ? 'no-status' : 'pay-status'">
          {{ detail.payStatus == 0 ? '未支付' : '已支付' }}
        </div>
        <div
          :class="detail.withdrawStatus == 0 ? 'no-status' : 'pay-status'"
          style="margin-left: 90px"
        >
          {{ detail.withdrawStatus == 0 ? '未提现' : '已提现' }}
        </div> -->
        <el-descriptions border class="margin-top" :column="4" size="medium">
          <el-descriptions-item>
            <template slot="label">账单编号</template>
            {{ detail.id }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单名称</template>
            {{ detail.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">合同编号</template>
            {{ detail.code }}
            <a style="cursor: pointer" @click="lookContract(detail.contractId)">
              查看合同
            </a>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">易票联商户订单号</template>
            {{ detail.payNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ detail.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">用户名称</template>
            {{ detail.memberName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">用户手机</template>
            {{ detail.memberPhone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单开始时间</template>
            {{ (detail.startDate || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单结束时间</template>
            {{ (detail.endDate || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">应支付日</template>
            {{ detail.billDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">实际支付日</template>
            {{ detail.realPayTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单状态</template>
            {{
              detail.status == '10'
                ? '已支付'
                : detail.status == '20'
                ? '待回款'
                : detail.status == '30'
                ? '待生效'
                : detail.status == '40'
                ? '已逾期'
                : detail.status == '50'
                ? '已作废'
                : ''
            }}
          </el-descriptions-item>
          <el-descriptions-item v-if="detail.status == '10'">
            <template slot="label">支付方式</template>
            {{
              detail.paymentWay == '1'
                ? '微信支付'
                : detail.paymentWay == '2'
                ? '公众号'
                : detail.paymentWay == '3'
                ? '中信'
                : detail.paymentWay == '10'
                ? '现金'
                : detail.paymentWay == '11'
                ? '微信转账'
                : detail.paymentWay == '12'
                ? '支付宝转账'
                : detail.paymentWay == '13'
                ? '银联转账'
                : detail.paymentWay == '14'
                ? '刷卡支付'
                : '其他'
            }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单应收</template>
            {{ detail.receivableAmount || 0 }}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单实收</template>
            {{ detail.payAmount || 0 }}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">总服务费</template>
            {{ detail.totalServiceFee || 0 }}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结算平台</template>
            {{
              detail.paymentWay == '1'
                ? detail.payWay == '1'
                  ? '易票联'
                  : detail.payWay == '3'
                  ? '中信'
                  : ''
                : ''
            }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">结算金额</template>
            {{ detail.realAmount || 0 }}元
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="bill-list">
        <div class="title" style="width: 70px">合同期账单</div>
        <el-table v-loading="loading" border :data="billList" stripe>
          <el-table-column
            align="center"
            label="费用类型"
            prop="billSubjectName"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="用量" prop="costMeterVal">
            <template slot-scope="scope">
              <span
                v-if="
                  ['3001', '3002', '3003', '3004', '3005', '3006'].includes(
                    scope.row.billSubjectId
                  )
                "
              >
                {{ scope.row.costMeterVal }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="单价" prop="unitPrice">
            <template slot-scope="scope">
              <span>
                {{ scope.row.unitPrice }}{{ scope.row.unitPriceName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="费用">
            <template slot-scope="scope">
              {{ scope.row.receivableAmount }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="费用生效时间"
            prop="createTime"
          />
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
        <Pagination
          :limit.sync="listQuery.pageSize"
          :page.sync="listQuery.pageNumber"
          :total-count="totalCount"
          @pagination="getBillList"
        />
      </div>

      <div class="bill-list">
        <div class="title" style="width: 70px">服务费明细</div>
        <el-table v-loading="loading" border :data="billFeeList" stripe>
          <el-table-column
            align="center"
            label="费用类型"
            prop="name"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="收费标准" prop="unit" />
          <!-- <el-table-column align="center" label="数量" prop="count" /> -->
          <el-table-column align="center" label="收费金额" prop="amount" />
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
        <Pagination
          :limit.sync="listQuery.pageSize"
          :page.sync="listQuery.pageNumber"
          :total-count="billtotalCount"
          @pagination="getBillList"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import { billServeFee } from '@/api/build/build'
  import { getBillDetailPage } from '@/api/bill/sheet'
  import { getDetail } from '@/api/bill/newIndex'
  import Pagination from '@/components/pagination.vue'
  export default {
    name: 'BillDetail',
    components: { Pagination },
    data() {
      return {
        id: '',
        detail: {},
        loading: false,
        totalCount: 0,
        billtotalCount: 0,
        listQuery: {
          id: '',
          pageNumber: 1,
          pageSize: 20,
        },
        renterList: [],
        tableData: [],
        billList: [],
        billFeeList: [],
        dailog: {
          height: 300,
          title: '',
          visible: false,
          visibleService: false,
          width: '600px',
          showfoot: false,
        },
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.listQuery.id = this.$route.query.id
      console.log(this.id)
      //this.getList()
      this.getBillList()

      this.fetchData()
    },

    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async goBack() {
        const detailPath = await handleActivePath(this.$route, true)
        await this.$router.push('/admin/bill/list')
        await this.delVisitedRoute(detailPath)
      },
      loadBillServeFee() {
        billServeFee({ id: this.detail.id }).then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.billFeeList = res.data
            // 手动判断是3的时候中信单独修改手续费
            if (+this.detail.payWay === 3) {
              this.billFeeList[2].unit = '0.23%'
            }
            this.billtotalCount = res.data.length
          }
        })
      },
      async fetchData() {
        this.loading = true
        getDetail(this.$route.query.id).then((res) => {
          if (res.code === 0) {
            console.log('getDetail>>>', res.data)
            this.detail = res.data
            this.loading = false
            this.loadBillServeFee()
          } else {
            this.loading = false
          }
        })
      },
      getList() {},
      lookContract(id) {
        this.$router.push({
          path: '/admin/house/contract/detail',
          query: { id },
        })
      },
      getBillList() {
        this.loading = true
        getBillDetailPage(this.listQuery).then((res) => {
          if (res.code === 0) {
            console.log('getBillDetailPage', res.data)
            const { records, total } = res.data
            this.billList = records
            this.totalCount = +total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .no-status {
    position: absolute;
    left: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 10px;
    color: #ff4d4f;
    background: #ffeded;
    border-color: #ffb8b9;
    border-radius: 50%;
  }

  .pay-status {
    position: absolute;
    left: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 10px;
    color: #13ce66;
    background: #e7faf0;
    border-color: #a1ebc2;
    border-radius: 50%;
  }

  .content {
    background-color: #f6f8f9 !important;

    .main {
      .basic {
        padding: 10px 0 0 10px;
        margin-bottom: 20px;
        background-color: #fff;

        .title {
          width: 60px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 2px solid #000;
        }
      }

      .bill-list {
        padding: 10px 0 20px 10px;
        margin-bottom: 20px;
        background-color: #fff;

        .title {
          width: 60px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 2px solid #000;
        }

        .title1 {
          width: 70px;
          padding-bottom: 10px;
          border-bottom: 2px solid #000;
        }

        .title2 {
          width: 90px;
          padding-bottom: 10px;
          border-bottom: 2px solid #000;
        }
      }
    }

    .el-form-item--small.el-form-item {
      margin-bottom: 0px;
    }

    .vab-footer {
      background-color: pink !important;
    }
  }
</style>
