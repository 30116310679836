<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="轮播类型">
            <el-select
              v-model="listQuery.type"
              clearable
              placeholder="轮播类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="发布状态" label-width="80px">
            <el-select
              v-model="listQuery.putaway"
              clearable
              placeholder="发布状态"
            >
              <el-option
                v-for="item in putAwayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="是否置顶" label-width="80px">
            <el-select
              v-model="listQuery.isTop"
              clearable
              placeholder="是否置顶"
            >
              <el-option
                v-for="item in isTopList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="关键字" label-width="80px">
            <el-input
              v-model="listQuery.keywords"
              clearable
              placeholder="关键字"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card">
      <el-row style="margin-bottom: 10px">
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="showDialog"
        >
          新增
        </el-button>
      </el-row> -->
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="标题" prop="title" />
      <el-table-column align="center" label="图片" prop="pic" width="80">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic"
            :preview-src-list="[scope.row.pic]"
            :src="scope.row.pic"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="轮播类型">
        <template slot-scope="scope">
          {{ fotmat(scope.row.type, typeList) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="发布状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.putaway">
            {{ fotmat(scope.row.putaway, putAwayList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ fotmat(scope.row.putaway, putAwayList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否置顶">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isTop">
            {{ fotmat(scope.row.isTop, isTopList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ fotmat(scope.row.isTop, isTopList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" />
      <el-table-column align="center" label="发布时间" prop="putawayTime" />

      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 1)"
          >
            详情/编辑
          </el-button>
          <el-button type="text" @click="handleDelete">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :top="dailog.top"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-col :span="24">
            <el-form-item label="标题" prop="title">
              <el-input
                v-model="formData.title"
                clearable
                :disabled="disabled"
                style="width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="链接" prop="link">
              <el-input
                v-model="formData.link"
                clearable
                :disabled="disabled"
                style="width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="小程序URL" prop="wxaLink">
              <el-input
                v-model="formData.wxaLink"
                clearable
                :disabled="disabled"
                style="width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="轮播类型" prop="type">
              <el-select
                v-model="formData.type"
                clearable
                :disabled="disabled"
                placeholder="轮播类型"
                style="width: 300px"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="发布状态" prop="putaway">
              <el-radio-group v-model="formData.putaway">
                <el-radio
                  v-for="item in putAwayList"
                  :key="item.value"
                  :disabled="disabled"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <!--<el-form-item label="发布状态" prop="putaway">
                <el-select
                  v-model="formData.putaway"
                  clearable
                  :disabled="disabled"
                  placeholder="发布状态"
                  style="width: 300px"
                >
                  <el-option
                    v-for="item in putAwayList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>-->
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否置顶" prop="isTop">
              <el-radio-group v-model="formData.isTop">
                <el-radio
                  v-for="item in isTopList"
                  :key="item.value"
                  :disabled="disabled"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <!--<el-form-item label="是否置顶" prop="isTop">
                <el-select
                  v-model="formData.isTop"
                  clearable
                  :disabled="disabled"
                  placeholder="是否置顶"
                  style="width: 300px"
                >
                  <el-option
                    v-for="item in isTopList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>-->
          </el-col>
          <el-col :span="24">
            <el-form-item label="排序号" prop="seq">
              <el-input
                v-model="formData.seq"
                :disabled="disabled"
                style="width: 300px"
                type="number"
              />
            </el-form-item>
            <div
              style="
                padding-left: 100px;
                color: #ccc;
                margin-top: -10px;
                font-size: 14px;
                margin-bottom: 4px;
              "
            >
              提示：按排序号数字升序排序，数字越小，排序越前
            </div>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图片" prop="pic">
              <JMUpload
                v-if="formData.pic != ''"
                v-model="formData.pic"
                :multiple="false"
                :number="1"
              />
              <!-- <el-image
                :preview-src-list="[formData.pic]"
                :src="formData.pic"
                style="width: 150px; height: 150px"
              /> -->
              <!-- <el-upload
                :action="domain"
                :before-upload="beforeUpload"
                :data="QiniuData"
                :file-list="fileList"
                :limit="1"
                multiple
                :on-error="uploadError"
                :on-exceed="onExceed"
                :on-remove="onRemove"
                :on-success="uploadSuccess"
              >
                <el-button v-show="!disabled" size="small" type="primary">
                  点击上传
                </el-button>
              </el-upload> -->
            </el-form-item>
          </el-col>
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import JMUpload from '@/components/jm-upload'
  import {
    bannerList,
    bannerDel,
    bannerAdd,
    bannerUpdate,
  } from '@/api/basicssetting'
  import { getUploadToken } from '@/api/common/index'
  const uploadUrlConf = {
    'qiniu.region.z0': '//upload.qiniup.com',
    'qiniu.region.z2': '//upload-z2.qiniup.com',
    'qiniu.region.z1': '//upload-z1.qiniup.com',
  }
  export default {
    name: 'Banners',
    components: {
      Pagination,
      Dialog,
      JMUpload,
    },
    data() {
      return {
        typeList: [
          {
            label: '首页轮播',
            value: 1,
          },
          {
            label: '广告轮播',
            value: 2,
          },
          {
            label: '关于我们',
            value: 3,
          },
        ],
        putAwayList: [
          {
            label: '未发布',
            value: 0,
          },
          {
            label: '已发布',
            value: 1,
          },
        ],
        isTopList: [
          {
            label: '否',
            value: 0,
          },
          {
            label: '是',
            value: 1,
          },
        ],
        disabled: false,
        dailog: {
          top: '5vh',
          height: 550,
          title: '轮播图管理',
          visible: false,
          width: '600px',
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          type: '',
          putaway: '',
          isTop: '',
          keywords: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        defaultImg: require('@/assets/default.jpg'),
        selection: [],
        rules: {
          title: { required: true, message: '请输入标题', trigger: 'blur' },
          pic: { required: true, message: '请上传图片', trigger: 'blur' },
          wxaLink: {
            required: true,
            message: '请输入小程序链接',
            trigger: 'blur',
          },
          // link: { required: true, message: '请输入链接', trigger: 'blur' },
          putaway: { required: true, message: '请选择', trigger: 'blur' },
          isTop: { required: true, message: '请选择', trigger: 'blur' },
          type: { required: true, message: '请选择轮播类型', trigger: 'blur' },
        },
        QiniuData: {
          key: '', //图片名字处理
          token: '', //七牛云token
          data: {},
        },
        domain: '', // 七牛云的上传地址（华东区）
        uploadUrl: '', //提交到后台图片地址
        fileList: [],
        urlList: [],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await bannerList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await bannerUpdate(this.formData)
            } else {
              // 新增
              await bannerAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      handleChange(value) {
        console.log('value', value)
        this.formData.pic = value
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await bannerDel(params)
          await this.getList()
        })
      },
      handleDelete(item) {
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const params = {
            ids: [item.id],
          }
          await bannerDel(params)
          await this.getList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          type: '',
          putaway: '',
          isTop: '',
          keywords: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        return res[0].label
      },
      async beforeUpload(file) {
        const fileName = file.name || file.path
        let ext = fileName.substring(
          fileName.lastIndexOf('.') + 1,
          fileName.length
        )
        let res = await getUploadToken({
          extension: ext,
        })
        if (res.code === 0) {
          this.domain = uploadUrlConf[res.data.region]
          this.QiniuData = {
            data: file,
            token: res.data.token,
            key: res.data.key,
          }
          this.uploadUrl = res.data.url
          return true
        }
        return false
      },
      uploadSuccess(response, file) {
        this.urlList.push({
          uid: file.uid,
          url: this.uploadUrl,
        })
        this.formData.pic = this.uploadUrl
        this.uploadUrl = ''
      },
      // 上传失败
      uploadError() {
        this.uploadUrl = ''
        this.$message({
          message: '上传出错，请重试！',
          type: 'error',
          center: true,
        })
      },
      onExceed() {
        this.$message({
          message: '最多可上传1张图片',
          type: 'error',
          center: true,
        })
      },
      onRemove(file) {
        const arr = this.urlList.filter((o) => {
          return o.uid !== file.uid
        })
        this.urlList = arr
        this.formData.pic = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-card.is-always-shadow {
    box-shadow: none !important;
  }

  .box-card.el-card {
    border: none !important;
  }
</style>
