<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="请输入商户名称"
            />
          </el-form-item>
          <el-form-item label="业务代码">
            <el-input
              v-model="listQuery.code"
              clearable
              placeholder="请输入业务代码"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-card class="box-card">
      <el-row style="margin-bottom: 10px">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </el-row>
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        stripe
        @select="selectRow"
        @select-all="selectRow"
      >
        <el-table-column align="center" type="selection" width="50" />
        <el-table-column align="center" label="序号" width="50">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="商户名称" prop="name" />
        <el-table-column
          align="center"
          label="最大单笔交易金额"
          prop="maxTxsAmount"
          width="180"
        />
        <el-table-column
          align="center"
          label="最小单笔交易金额"
          prop="minTxsAmount"
          width="180"
        />
        <el-table-column align="center" label="收费比率" prop="stageFeeRate" />
        <el-table-column align="center" label="结算周期" prop="settleCycle" />
        <el-table-column align="center" label="是否允许信用卡支付" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.creditcardsEnabled">
              {{ fotmat(scope.row.creditcardsEnabled, creditcardsEnabledList) }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ fotmat(scope.row.creditcardsEnabled, creditcardsEnabledList) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="保底手续费"
          prop="feeMin"
          width="120"
        />
        <el-table-column
          align="center"
          label="单笔交易计费"
          prop="stageFeePer"
          width="120"
        />
        <el-table-column
          align="center"
          label="退款手续费费率"
          prop="refundFeeRate"
          width="120"
        />
        <el-table-column
          align="center"
          label="退款手续费"
          prop="refundFeePer"
          width="120"
        />
        <el-table-column
          align="center"
          label="封顶手续费"
          prop="feeMax"
          width="120"
        />
        <el-table-column align="center" label="是否允许退款" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.refundEnabled">
              {{ fotmat(scope.row.refundEnabled, refundEnabledList) }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ fotmat(scope.row.refundEnabled, refundEnabledList) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="创建时间"
          prop="createTime"
          width="180"
        />

        <el-table-column align="center" fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="text"
              @click="handleDetail(scope.row, 0)"
            >
              详情
            </el-button> -->
            <el-button
              size="mini"
              type="text"
              @click="handleDetail(scope.row, 1)"
            >
              详情/编辑
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>

      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :title="dailog.title"
        :visible="dailog.visible"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-form
            ref="formData"
            label-width="130px"
            :model="formData"
            :rules="rules"
            size="small"
          >
            <el-col :span="12">
              <el-form-item label="商户名称" prop="shopId">
                <el-select
                  v-model="formData.shopId"
                  clearable
                  :disabled="disabled"
                  filterable
                  style="max-width: 300px"
                >
                  <el-option
                    v-for="item in shopListData"
                    :key="item.id"
                    :label="item.shortName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业务代码" prop="code">
                <el-input
                  v-model="formData.code"
                  clearable
                  :disabled="disabled"
                  style="max-width: 300px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="交易比率" prop="stageFeeRate">
                <el-input
                  v-model="formData.stageFeeRate"
                  clearable
                  :disabled="disabled"
                  style="max-width: 300px"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单笔交易计费" prop="stageFeePer">
                <el-input
                  v-model="formData.stageFeePer"
                  clearable
                  :disabled="disabled"
                  style="max-width: 300px"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="保底手续费" prop="feeMin">
                <el-input
                  v-model="formData.feeMin"
                  :disabled="disabled"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="封顶手续费" prop="feeMax">
                <el-input
                  v-model="formData.feeMax"
                  :disabled="disabled"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="退款手续费费率" prop="refundFeeRate">
                <el-input
                  v-model="formData.refundFeeRate"
                  :disabled="disabled"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="退款手续费单笔" prop="refundFeePer">
                <el-input
                  v-model="formData.refundFeePer"
                  :disabled="disabled"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最大单笔交易金额" prop="maxTxsAmount">
                <el-input
                  v-model="formData.maxTxsAmount"
                  :disabled="disabled"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最小单笔交易金额" prop="minTxsAmount">
                <el-input
                  v-model="formData.minTxsAmount"
                  :disabled="disabled"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结算周期" prop="settleCycle">
                <el-input v-model="formData.settleCycle" :disabled="disabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="允许退款" prop="refundEnabled">
                <el-radio-group v-model="formData.refundEnabled">
                  <el-radio
                    v-for="item in refundEnabledList"
                    :key="item.value"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <!--<el-form-item label="允许退款" prop="refundEnabled">
                <el-select
                  v-model="formData.refundEnabled"
                  clearable
                  :disabled="disabled"
                >
                  <el-option
                    v-for="item in refundEnabledList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>-->
            </el-col>
            <el-col :span="12">
              <el-form-item label="允许信用卡支付" prop="creditcardsEnabled">
                <el-radio-group v-model="formData.creditcardsEnabled">
                  <el-radio
                    v-for="item in creditcardsEnabledList"
                    :key="item.value"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>

              <!--<el-form-item
                label="是否允许信用卡支付"
                prop="creditcardsEnabled"
              >
                <el-select
                  v-model="formData.creditcardsEnabled"
                  clearable
                  :disabled="disabled"
                >
                  <el-option
                    v-for="item in creditcardsEnabledList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>-->
            </el-col>
          </el-form>
        </template>
      </Dialog>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { rateList, rateDel, rateAdd, rateUpdate } from '@/api/merchant/index'
  import { shopList } from '@/api/basicssetting'
  export default {
    name: 'BasicssettingRate',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dailog: {
          height: 400,
          title: '商户成本费用配置',
          visible: false,
          width: '1100px',
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        rules: {
          shopId: { required: true, message: '请选择', trigger: 'blur' },
          code: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          refundEnabled: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          refundFeeRate: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          refundFeePer: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          creditcardsEnabled: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          settleCycle: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
        },
        shopListData: [],
        creditcardsEnabledList: [
          {
            label: '禁用 ',
            value: 0,
          },
          {
            label: '允许 ',
            value: 1,
          },
        ],
        refundEnabledList: [
          {
            label: '不允许 ',
            value: 0,
          },
          {
            label: '允许 ',
            value: 1,
          },
        ],
      }
    },
    created() {
      this.getList()
      this.getShopList()
    },
    methods: {
      async getShopList() {
        const {
          data: { list },
        } = await shopList()
        this.shopListData = list
      },
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await rateList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        console.log('handleConfirm', this.disabled)
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await rateUpdate(this.formData)
            } else {
              // 新增
              await rateAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await rateDel(params)
          await this.getList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        if (res.length > 0) {
          return res[0].label
        } else {
          return ''
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-card.is-always-shadow {
    box-shadow: none !important;
  }
  .box-card.el-card {
    border: none !important;
  }
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
</style>
