<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="关键字">
            <el-input
              v-model="listQuery.keywords"
              clearable
              placeholder="关键字"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="收费名称" prop="name" width="220" />
      <el-table-column
        align="left"
        label="默认收费值"
        prop="defaultVal"
        width="220"
      />
      <el-table-column align="left" label="单位" prop="unit" />
      <el-table-column align="left" label="收费编码" prop="code" width="220" />
      <el-table-column
        align="left"
        label="区间最大值"
        prop="maxVal"
        width="220"
      />
      <el-table-column
        align="left"
        label="区间最小值"
        prop="minVal"
        width="220"
      />
      <el-table-column align="left" label="备注" prop="remark" />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="220"
      />
      <el-table-column
        align="center"
        label="修改时间"
        prop="updateTime"
        width="220"
      />
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 0)"
          >
            详情
          </el-button> -->
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 1)"
          >
            详情/编辑
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-col :span="24">
            <el-form-item label="收费名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="默认收费值" prop="defaultVal">
              <el-input
                v-model="formData.defaultVal"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="单位" prop="unit">
              <el-input
                v-model="formData.unit"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收费编码" prop="code">
              <el-input
                v-model="formData.code"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="区间最大值" prop="maxVal">
              <el-input
                v-model="formData.maxVal"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="区间最小值" prop="minVal">
              <el-input
                v-model="formData.minVal"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="formData.remark"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import {
    chargeList,
    chargeDel,
    chargeAdd,
    chargeUpdate,
  } from '@/api/basicssetting'
  export default {
    name: 'Charge',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      var checkNumber = (rule, value, callback) => {
        if (value > this.formData.maxVal || value < this.formData.minVal) {
          callback(
            new Error(
              `请输入${this.formData.minVal}~${this.formData.maxVal}之间的数值`
            )
          )
        } else {
          callback()
        }
      }
      return {
        disabled: false,
        dailog: {
          height: 400,
          title: '收费标准',
          visible: false,
          width: '600px',
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        rules: {
          name: { required: true, message: '请输入', trigger: 'blur' },
          defaultVal: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' },
          ],
          shareContent: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          unit: { required: true, message: '请输入', trigger: 'blur' },
          code: { required: true, message: '请输入', trigger: 'blur' },
          maxVal: { required: true, message: '请选择', trigger: 'blur' },
          // remark: { required: true, message: '请选择', trigger: 'blur' },
          minVal: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await chargeList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await chargeUpdate(this.formData)
            } else {
              // 新增
              await chargeAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await chargeDel(params)
          await this.getList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
        }
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-card.is-always-shadow {
    box-shadow: none !important;
  }
  .box-card.el-card {
    border: none !important;
  }
</style>
