var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "basic" },
        [
          _c("el-page-header", {
            attrs: { content: "账单详情" },
            on: { back: _vm.goBack },
          }),
          _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { border: "", column: 4, size: "medium" },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单编号")]),
                  _vm._v(" " + _vm._s(_vm.detail.id) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单名称")]),
                  _vm._v(" " + _vm._s(_vm.detail.name) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("合同编号")]),
                  _vm._v(" " + _vm._s(_vm.detail.code) + " "),
                  _c(
                    "a",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.lookContract(_vm.detail.contractId)
                        },
                      },
                    },
                    [_vm._v(" 查看合同 ")]
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("易票联商户订单号"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.detail.payNo) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("商户名称")]),
                  _vm._v(" " + _vm._s(_vm.detail.shopName) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("用户名称")]),
                  _vm._v(" " + _vm._s(_vm.detail.memberName) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("用户手机")]),
                  _vm._v(" " + _vm._s(_vm.detail.memberPhone) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单开始时间")]),
                  _vm._v(
                    " " +
                      _vm._s((_vm.detail.startDate || "").split(" ")[0]) +
                      " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单结束时间")]),
                  _vm._v(
                    " " + _vm._s((_vm.detail.endDate || "").split(" ")[0]) + " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("应支付日")]),
                  _vm._v(" " + _vm._s(_vm.detail.billDate) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("实际支付日")]),
                  _vm._v(" " + _vm._s(_vm.detail.realPayTime) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单状态")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detail.status == "10"
                          ? "已支付"
                          : _vm.detail.status == "20"
                          ? "待回款"
                          : _vm.detail.status == "30"
                          ? "待生效"
                          : _vm.detail.status == "40"
                          ? "已逾期"
                          : _vm.detail.status == "50"
                          ? "已作废"
                          : ""
                      ) +
                      " "
                  ),
                ],
                2
              ),
              _vm.detail.status == "10"
                ? _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [_vm._v("支付方式")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.detail.paymentWay == "1"
                              ? "微信支付"
                              : _vm.detail.paymentWay == "2"
                              ? "公众号"
                              : _vm.detail.paymentWay == "3"
                              ? "中信"
                              : _vm.detail.paymentWay == "10"
                              ? "现金"
                              : _vm.detail.paymentWay == "11"
                              ? "微信转账"
                              : _vm.detail.paymentWay == "12"
                              ? "支付宝转账"
                              : _vm.detail.paymentWay == "13"
                              ? "银联转账"
                              : _vm.detail.paymentWay == "14"
                              ? "刷卡支付"
                              : "其他"
                          ) +
                          " "
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单应收")]),
                  _vm._v(
                    " " + _vm._s(_vm.detail.receivableAmount || 0) + "元 "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("账单实收")]),
                  _vm._v(" " + _vm._s(_vm.detail.payAmount || 0) + "元 "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("总服务费")]),
                  _vm._v(" " + _vm._s(_vm.detail.totalServiceFee || 0) + "元 "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("结算平台")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detail.paymentWay == "1"
                          ? _vm.detail.payWay == "1"
                            ? "易票联"
                            : _vm.detail.payWay == "3"
                            ? "中信"
                            : ""
                          : ""
                      ) +
                      " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [_vm._v("结算金额")]),
                  _vm._v(" " + _vm._s(_vm.detail.realAmount || 0) + "元 "),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bill-list" },
        [
          _c("div", { staticClass: "title", staticStyle: { width: "70px" } }, [
            _vm._v("合同期账单"),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { border: "", data: _vm.billList, stripe: "" },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png"),
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用类型",
                  prop: "billSubjectName",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "用量", prop: "costMeterVal" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        [
                          "3001",
                          "3002",
                          "3003",
                          "3004",
                          "3005",
                          "3006",
                        ].includes(scope.row.billSubjectId)
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(scope.row.costMeterVal) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "单价", prop: "unitPrice" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.unitPrice) +
                              _vm._s(scope.row.unitPriceName) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "费用" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.receivableAmount) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用生效时间",
                  prop: "createTime",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              limit: _vm.listQuery.pageSize,
              page: _vm.listQuery.pageNumber,
              "total-count": _vm.totalCount,
            },
            on: {
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNumber", $event)
              },
              pagination: _vm.getBillList,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bill-list" },
        [
          _c("div", { staticClass: "title", staticStyle: { width: "70px" } }, [
            _vm._v("服务费明细"),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { border: "", data: _vm.billFeeList, stripe: "" },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png"),
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用类型",
                  prop: "name",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "收费标准", prop: "unit" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "收费金额", prop: "amount" },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              limit: _vm.listQuery.pageSize,
              page: _vm.listQuery.pageNumber,
              "total-count": _vm.billtotalCount,
            },
            on: {
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNumber", $event)
              },
              pagination: _vm.getBillList,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }