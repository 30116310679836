<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="账单项目" label-width="80px">
            <el-input
              v-model="listQuery.subjectName"
              clearable
              placeholder="账单项目"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card"> -->
    <!-- <el-row style="margin-bottom: 10px">
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="showDialog"
        >
          新增
        </el-button>
      </el-row> -->
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="商户名称" prop="shopName" />
      <el-table-column align="left" label="账单项目" prop="subjectName" />
      <el-table-column align="left" label="默认单价" prop="defaultUnitPrice" />
      <el-table-column align="center" label="是否启用">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.checked">
            {{ fotmat(scope.row.checked, checkedList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ fotmat(scope.row.checked, checkedList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 0)"
          >
            详情
          </el-button> -->
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 1)"
          >
            详情/编辑
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-col :span="24">
            <el-form-item label="商户名称" prop="shopId">
              <el-select
                v-model="formData.shopId"
                clearable
                :disabled="disabled"
                filterable
              >
                <el-option
                  v-for="item in shopListData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="账单项目" prop="subjectId">
              <el-select
                v-model="formData.subjectId"
                clearable
                :disabled="disabled"
              >
                <el-option
                  v-for="item in billTypeListData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="默认单价" prop="defaultUnitPrice">
              <el-input
                v-model="formData.defaultUnitPrice"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否启用" prop="checked">
              <el-radio-group v-model="formData.checked">
                <el-radio
                  v-for="item in checkedList"
                  :key="item.value"
                  :disabled="disabled"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>

              <!-- <el-select
                v-model="formData.checked"
                clearable
                :disabled="disabled"
              >
                <el-option
                  v-for="item in checkedList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </el-form-item>
          </el-col>
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import {
    billList,
    billDel,
    billAdd,
    billUpdate,
    shopList,
    billTypeList,
  } from '@/api/basicssetting'
  export default {
    name: 'BasicssettingBill',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dailog: {
          title: '账单均摊设置',
          visible: false,
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          subjectName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        rules: {
          shopId: { required: true, message: '请输入', trigger: 'blur' },
          subjectId: { required: true, message: '请选择', trigger: 'blur' },
          defaultUnitPrice: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          checked: { required: true, message: '请选择', trigger: 'blur' },
        },
        checkedList: [
          {
            label: '否',
            value: 0,
          },
          {
            label: '是',
            value: 1,
          },
        ],
        shopListData: [],
        billTypeListData: [],
      }
    },
    created() {
      this.getList()
      this.getShopList()
      this.getBillTypeList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await billList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      async getShopList() {
        const {
          data: { records },
        } = await shopList()
        this.shopListData = records
      },
      async getBillTypeList() {
        const {
          data: { records },
        } = await billTypeList()
        this.billTypeListData = records
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await billUpdate(this.formData)
            } else {
              // 新增
              await billAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await billDel(params)
          await this.getList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shopName: '',
          subjectName: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        return res[0].label
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }

  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-card.is-always-shadow {
    box-shadow: none !important;
  }

  .box-card.el-card {
    border: none !important;
  }
</style>
