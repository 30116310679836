<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="90px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="分类名称">
            <el-select v-model="listQuery.articleCategoryId" clearable>
              <el-option
                v-for="item in articleCategoryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="发布状态">
            <el-select v-model="listQuery.putaway" clearable>
              <el-option
                v-for="item in putAwayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="置顶状态">
            <el-select v-model="listQuery.isTop" clearable>
              <el-option
                v-for="item in topList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="关键字" prop="keywords">
            <el-input
              v-model="formData.keywords"
              clearable
              placeholder="请输入关键字"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="reset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>
    <div class="addButton">
      <el-button
        icon="el-icon-plus"
        size="mini"
        type="primary"
        @click="handleAdd"
      >
        新增
      </el-button>
    </div>
    <el-table v-loading="loading" border :data="tableData" stripe>
      <el-table-column align="center" label="序号" width="50">
        <template slot-scope="scope">
          {{
            (listQuery.pageNumber - 1) * listQuery.pageSize + scope.$index + 1
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="类别名称"
        prop="categoryName"
        width="200"
      />
      <el-table-column align="center" label="文章封面" width="150">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.articleLogo"
            :preview-src-list="[scope.row.articleLogo]"
            :src="scope.row.articleLogo"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />

          <!-- <el-popover placement="right" title="" trigger="hover">
            <el-image
              slot="reference"
              :alt="scope.row.articleLogo"
              :src="scope.row.articleLogo"
              style="max-width: 100px; max-height: 100px"
            />
            <el-image
              :src="scope.row.articleLogo"
              style="width: 300px; height: 300px"
            />
          </el-popover> -->
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="文章标题"
        prop="articleTitle"
        width="250"
      />
      <el-table-column
        align="center"
        label="文章摘要"
        prop="digest"
        width="250"
      />
      <el-table-column align="center" label="作者" prop="author" width="250" />
      <el-table-column align="center" label="发布状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.putaway">
            {{ format(scope.row.putaway, putAwayList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.putaway, putAwayList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="置顶状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isTop">
            {{ format(scope.row.isTop, topList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ format(scope.row.isTop, topList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="置顶时间"
        prop="topTime"
        width="200"
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="250"
      />
      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleEdit(scope.row)">
            编辑
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
          <el-button size="mini" type="text" @click="handleTop(scope.row.id)">
            {{ scope.row.isTop === 0 ? '置顶' : '取消置顶' }}
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handlePutAway(scope.row.id)"
          >
            {{ scope.row.putaway === 0 ? '发布' : '取消发布' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-row>
            <el-form-item label="文章分类" prop="articleCategoryId">
              <el-select
                v-model="formData.articleCategoryId"
                placeholder="请选择文章分类"
                style="width: 300px"
              >
                <el-option
                  v-for="item in articleCategoryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="文章标题" prop="articleTitle">
              <el-input
                v-model="formData.articleTitle"
                clearable
                placeholder="请输入文章标题"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="文章封面" prop="articleLogo">
              <el-image
                :preview-src-list="[formData.articleLogo]"
                :src="formData.articleLogo"
                style="width: 150px; height: 150px"
              />
              <el-upload
                :action="domain"
                :before-upload="beforeUpload"
                :data="QiniuData"
                :file-list="fileList"
                :limit="1"
                multiple
                :on-error="uploadError"
                :on-exceed="onExceed"
                :on-remove="onRemove"
                :on-success="uploadSuccess"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="作者" prop="author">
              <el-input
                v-model="formData.author"
                clearable
                placeholder="请输入作者"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="文章摘要" prop="digest">
              <el-input
                v-model="formData.digest"
                clearable
                placeholder="请输入文章摘要"
                style="max-width: 300px"
              />
            </el-form-item>
            <el-form-item label="发布状态" prop="putaway">
              <el-radio-group v-model="formData.putaway">
                <el-radio
                  v-for="item in putAwayList"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="置顶状态" prop="isTop">
              <el-radio-group v-model="formData.isTop">
                <el-radio
                  v-for="item in topList"
                  :key="item.value"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="内容" prop="content">
              <JEditor v-model="formData.content" />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { categoryOptions } from '@/api/article/articlecategory'
  import {
    articleList,
    deleteArticle,
    addArticle,
    editArticle,
    toggleIsTop,
    togglePutAway,
  } from '@/api/article/article'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import JEditor from '@/components/j-editor'
  //import VabQuill from '@/extra/VabQuill'
  import { getUploadToken } from '@/api/common/index'
  const uploadUrlConf = {
    'qiniu.region.z0': '//upload.qiniup.com',
    'qiniu.region.z2': '//upload-z2.qiniup.com',
    'qiniu.region.z1': '//upload-z1.qiniup.com',
  }
  export default {
    name: 'Article',
    components: {
      Pagination,
      Dialog,
      JEditor,
    },
    data() {
      return {
        defaultImg: require('@/assets/default.jpg'),
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          articleCategoryId: '',
          putaway: '',
          isTop: '',
          keywords: '',
        },
        dailog: {
          height: 620,
          title: '文章',
          visible: false,
          width: '80%',
        },
        rules: {
          articleCategoryId: [
            { required: true, message: '请选择文章分类', trigger: 'blur' },
          ],
          author: [{ required: true, message: '请输入作者', trigger: 'blur' }],
          articleLogo: [
            { required: true, message: '请上传文章封面', trigger: 'blur' },
          ],
          articleTitle: [
            { required: true, message: '请输入文章标题', trigger: 'blur' },
          ],
          digest: [
            { required: true, message: '请输入文章摘要', trigger: 'blur' },
          ],
          content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
          putaway: [
            { required: true, message: '请选择发布状态', trigger: 'blur' },
          ],
          isTop: [
            { required: true, message: '请选择置顶状态', trigger: 'blur' },
          ],
        },
        formData: {},
        totalCount: 0,
        tableData: [],
        articleCategoryList: [],
        putAwayList: [
          {
            label: '未发布',
            value: 0,
          },
          {
            label: '已发布',
            value: 1,
          },
        ],
        topList: [
          {
            label: '未置顶',
            value: 0,
          },
          {
            label: '已置顶',
            value: 1,
          },
        ],
        loading: false,
        // 格式化
        format(val, list) {
          const res = list.filter((item) => item.value === val)
          if (res.length > 0) {
            return res[0].label
          } else {
            return '未知'
          }
        },
        QiniuData: {
          key: '', //图片名字处理
          token: '', //七牛云token
          data: {},
        },
        domain: '', // 七牛云的上传地址（华东区）
        uploadUrl: '', //提交到后台图片地址
        fileList: [],
        urlList: [],
      }
    },
    created() {
      this.getList()
      this.getArticleCategoryList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await articleList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //获取文章分类
      getArticleCategoryList() {
        categoryOptions().then((res) => {
          this.articleCategoryList = res.data
        })
      },
      // 新增
      handleAdd() {
        this.dailog.visible = true
        this.dailog.title = '新增文章'
        this.urlList = []
      },
      // 删除
      handleDelete(id) {
        this.$confirm('是否删除该文章?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const ids = id
          deleteArticle({ ids }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      //置顶
      handleTop(id) {
        this.$confirm('确认切换置顶状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        }).then(() => {
          toggleIsTop({ id }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      //发布状态
      handlePutAway(id) {
        this.$confirm('确认切换发布状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        }).then(() => {
          togglePutAway({ id }).then((res) => {
            this.commonMessage(res)
            console.log(res)
          })
        })
      },
      // 编辑
      handleEdit(row) {
        this.dailog.visible = true
        this.dailog.title = '编辑文章'
        this.urlList = []
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
          console.log(this.formData)
        })
      },
      // 确定新增/修改
      handleConfirm() {
        this.$refs['formData'].validate((valid) => {
          console.log(valid)
          if (valid) {
            if (this.urlList.length > 0) {
              this.formData.articleLogo = this.urlList[0].url
            }
            if (this.dailog.title === '新增文章') {
              addArticle(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            } else {
              editArticle(this.formData).then((res) => {
                this.commonMessage(res)
                this.handleCloseDialog()
              })
            }
          }
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['formData'].resetFields()
      },
      commonMessage(res) {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg,
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
          })
        }
      },
      /** 七牛上传 */
      handleRemove() {
        this.uploadUrl = ''
      },
      async beforeUpload(file) {
        const fileName = file.name || file.path
        let ext = fileName.substring(
          fileName.lastIndexOf('.') + 1,
          fileName.length
        )
        let res = await getUploadToken({
          extension: ext,
        })
        if (res.code === 0) {
          this.domain = uploadUrlConf[res.data.region]
          this.QiniuData = {
            data: file,
            token: res.data.token,
            key: res.data.key,
          }
          this.uploadUrl = res.data.url
          console.log(res.data.url, 'res.data.url==>')
          return true
        }
        return false
      },
      uploadSuccess(response, file) {
        this.urlList.push({
          uid: file.uid,
          url: this.uploadUrl,
        })
        this.formData.articleLogo = this.uploadUrl
        this.uploadUrl = ''
      },
      // 上传失败
      uploadError() {
        this.uploadUrl = ''
        this.$message({
          message: '上传出错，请重试！',
          type: 'error',
          center: true,
        })
      },
      onExceed() {
        this.$message({
          message: '最多可上传1张图片',
          type: 'error',
          center: true,
        })
      },
      onRemove(file) {
        const arr = this.urlList.filter((o) => {
          return o.uid !== file.uid
        })
        this.urlList = arr
        this.formData.articleLogo = ''
      },
      reset() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          articleCategoryId: '',
          putaway: '',
          isTop: '',
          keywords: '',
        }
        this.getList()
      },
      handleAddImg() {
        this.form.content +=
          '<img src="https://cdn.jsdelivr.net/gh/chuzhixin/image/ewm.png" />'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
      }
    }
    .addButton {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .value {
      width: 200px;
      height: 100%;
      // display: flex;
      .vab {
        width: 100%;
        height: 100%;
      }
      img {
        margin-right: 10px;
        width: 100%;
        height: 100%;
      }
    }
    // :deep() {
    //   .ql-editor {
    //     img {
    //       width: 60%;
    //       height: 60%;
    //     }
    //   }
    //   .el-descriptions__body {
    //     img {
    //       width: 100%;
    //       height: 200px;
    //     }
    //   }
    // }
  }
</style>
