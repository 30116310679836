<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="用户名称">
            <el-input v-model="listQuery.memberName" placeholder="用户名称" />
          </el-form-item>
          <el-form-item label="账单名称" label-width="80px">
            <el-input v-model="listQuery.billName" placeholder="账单名称" />
          </el-form-item>
          <el-form-item label="是否已读" label-width="80px">
            <el-select v-model="listQuery.readStatus" placeholder="是否已读">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>

            <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="用户名称 "
        prop="memberName"
        width="180"
      />
      <el-table-column align="center" label="账单名称" prop="billName" />
      <el-table-column
        align="center"
        label="推送时间"
        prop="sendTime"
        width="180"
      />
      <el-table-column
        align="center"
        label="是否已读"
        prop="readStatus"
        width="150"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.readStatus === 0" type="danger">
            {{ readStatusFormat(scope.row) }}
          </el-tag>
          <el-tag v-if="scope.row.readStatus === 1" type="success">
            {{ readStatusFormat(scope.row) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="已读时间"
        prop="readTime"
        width="220"
      />
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row.id)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-descriptions border class="margin-top" :column="3" size="medium">
          <el-descriptions-item>
            <template slot="label">商户名称</template>
            {{ formData.shopName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">用户名称</template>
            {{ formData.memberName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单名称</template>
            {{ formData.billName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">合同编号</template>
            {{ formData.code }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单开始日期</template>
            {{ (formData.startDate || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单结束日期</template>
            {{ (formData.endDate || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">应支付日</template>
            {{ (formData.billDate || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">账单状态</template>
            {{ statusFormat(formData.status) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">原价</template>
            {{ formData.xxxx }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">应收金额</template>
            {{ formData.receivableAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">实收金额</template>
            {{ formData.realAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">平台服务费</template>
            {{ formData.billServiceAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">支付分账服务费</template>
            {{ formData.splitServiceAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">自动提现服务费</template>
            {{ formData.withdrawProcedureFee }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">支付手续费</template>
            {{ formData.payServiceAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">可提前支付时间</template>
            {{ (formData.canPayTime || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">实际支付时间</template>
            {{ (formData.realPayTime || '').split(' ')[0] }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">支付id</template>
            {{ formData.paymentId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">支付方式</template>
            {{ paymentWayFormat(formData.paymentWay) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">支付状态</template>
            {{ payStatusFormat(formData.payStatus) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">支付渠道交易号</template>
            {{ formData.payNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">易票联分账时间</template>
            {{ formData.splitTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">回调地址</template>
            {{ formData.xxxx }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            {{ formData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">退款金额</template>
            {{ formData.refundAmount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">申请退款原因</template>
            {{ formData.refundReason }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">退款状态</template>
            {{ refundStatusFormat(formData.refundStatus) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">退款时间</template>
            {{ formData.refundTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">拒绝原因</template>
            {{ formData.rejectReason }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间</template>
            {{ formData.createTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">更新时间</template>
            {{ formData.updateTime }}
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { pushrecordList, pushrecordDetail } from '@/api/bill/pushrecord'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialogOther.vue'
  export default {
    name: 'BillSheet',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,

        dailog: {
          height: 480,
          title: '',
          visible: false,
          width: '900px',
        },
        formData: {
          code: '',
        },

        ddd: 'zc',

        options: [
          {
            value: '',
            label: '请选择是否已读',
          },
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        value: '',
        input: '',
        listQuery: {
          memberName: '',
          billName: '',
          readStatus: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      searchReset() {
        this.listQuery = {}
        this.getList()
      },
      //查询
      getList() {
        this.loading = true
        pushrecordList(this.listQuery).then((res) => {
          if (res.code === 0) {
            // console.log('res>>>', res.data)
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        // console.log('单选===', selection)
        console.log('单选===', row)
      },
      //编辑
      handleDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '推送记录明细查看'
        this.$nextTick(() => {
          // var onData = JSON.parse(JSON.stringify(row))
          // console.log('onData>>>', row)
          this.loading = true
          var idParams = {
            id: row,
          }
          pushrecordDetail(idParams).then((res) => {
            // console.log('res>>>', res)
            if (res.code === 0) {
              this.formData = res.data
              this.loading = false
            } else {
              this.loading = false
            }
          })
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        // console.log(this.formData, 588)
        this.$refs['formData'].validate((valid) => {
          // console.log(valid)
          if (valid) {
            // menuSave(this.formData).then((res) => {
            //   this.commonMessage(res)
            // })
          }
        })
      },
      // 状态赋值
      readStatusFormat(row) {
        // console.log('单选===', row)
        let state = row.readStatus
        if (state === 0) {
          return '否'
        } else if (state === 1) {
          return '是'
        }
      },
      refundStatusFormat(status) {
        // console.log('单选===', row)
        // let state = row.readStatus
        if (status === 0) {
          return '未退款'
        } else if (status === 1) {
          return '待处理'
        } else if (status === 2) {
          return '已同意'
        } else if (status === 3) {
          return '退款失败（拒绝）'
        } else if (status === 4) {
          return '已取消'
        } else if (status === 5) {
          return '退款成功'
        }
      },
      payStatusFormat(status) {
        if (status === 0) {
          return '未付款'
        } else if (status === 1) {
          return '已支付'
        }
      },
      paymentWayFormat(status) {
        if (status === 1) {
          return '小程序'
        } else if (status === 10) {
          return '现金'
        } else if (status === 11) {
          return '微信转账'
        } else if (status === 12) {
          return '支付宝转账'
        } else if (status === 13) {
          return '银联转账'
        } else if (status === 14) {
          return '刷卡支付'
        } else if (status === 99) {
          return '其他'
        }
      },
      statusFormat(status) {
        if (status === 10) {
          return '已完成'
        } else if (status === 20) {
          return '待回款'
        } else if (status === 30) {
          return '待生效'
        } else if (status === 40) {
          return '已逾期'
        } else if (status === 50) {
          return '已作废'
        }
      },
      //刷新
      refresh() {
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-dialog .el-form-item__label {
    width: 150px !important;
  }
</style>
