<template>
  <div class="code-poster-management">
    <div class="poster-setting">
      <div class="global-item">
        <div class="item-label">海报Slogan</div>
        <div class="item-content">
          <el-input
            v-model="topForm.posterTitle"
            maxlength="15"
            placeholder="请输入海报Slogan"
            show-word-limit
          />
        </div>
        <div>
          <el-button type="primary" @click="saveConfig">保存</el-button>
        </div>
      </div>
      <div class="global-item">
        <div class="item-label">海报文案</div>
        <div class="item-content">
          <el-input
            v-model="topForm.posterText"
            maxlength="30"
            placeholder="请输入海报文案"
            show-word-limit
            type="textarea"
          />
        </div>
        <div>
          <el-button type="primary" @click="saveConfig">保存</el-button>
        </div>
      </div>
    </div>
    <div class="form-table-container">
      <vab-query-form>
        <vab-query-form-top-panel>
          <el-form
            ref="form"
            :inline="true"
            label-width="80px"
            :model="listQuery"
            @submit.native.prevent
          >
            <el-form-item label="海报名称">
              <el-input
                v-model="listQuery.title"
                clearable
                placeholder="海报名称"
              />
            </el-form-item>
            <el-form-item label="用户端">
              <el-select v-model="listQuery.client" placeholder="请选择">
                <el-option label="全部" value="" />
                <el-option label="管理后台" value="PLATFORM" />
                <el-option label="房东端(Web)" value="SHOP" />
                <el-option label="租户端" value="MEMBER" />
                <el-option
                  label="房东端(小程序)"
                  value="SHOP_WECHAT_SMALL_ROUTINE"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                native-type="submit"
                type="primary"
                @click="handleQuery"
              >
                查询
              </el-button>
              <el-button icon="el-icon-refresh-left" @click="resetQuery">
                重置
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-top-panel>
        <vab-query-form-left-panel :span="24">
          <el-button icon="el-icon-plus" type="primary" @click="add">
            新增海报头图
          </el-button>
        </vab-query-form-left-panel>
      </vab-query-form>

      <el-table
        v-loading="loading"
        border
        :data="tableData"
        stripe
        @selection-change="setSelectRows"
      >
        <!-- <el-table-column align="center" type="selection" width="50" /> -->
        <el-table-column align="center" label="序号" width="55">
          <template #default="{ $index }">
            <span>
              {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="海报名称" prop="title" />
        <el-table-column align="center" label="图片" width="260">
          <template slot-scope="scope">
            <img
              alt=""
              :src="scope.row.img"
              style="width: 240px; height: 120px"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="用户端" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.client === 'PLATFORM'">管理后台</span>
            <span v-if="scope.row.client === 'SHOP'">房东端(Web)</span>
            <span v-if="scope.row.client === 'MEMBER'">租户端</span>
            <span v-if="scope.row.client === 'SHOP_WECHAT_SMALL_ROUTINE'">
              房东端(小程序)
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否启用" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 'DISABLED'">否</span>
            <span v-if="scope.row.status === 'ENABLED'">是</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="创建时间"
          prop="createTime"
          width="150"
        />

        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="fetchData"
      />
    </div>
    <AddVisibleVue
      v-if="addVisible"
      :add-visible.sync="addVisible"
      :row-data="rowData"
      @confirm="confirm"
    />
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import AddVisibleVue from './components/addCodePoster.vue'
  import {
    getPosterImg,
    addPosterImg,
    updatePosterImg,
    delPosterImg,
    getPosterConfig,
    updatePosterConfig,
  } from '@/api/basicssetting/newIndex'
  export default {
    name: 'CodePosterManagement',
    components: {
      Pagination,
      AddVisibleVue,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        topForm: {
          posterTitle: '',
          posterText: '',
        },
        rowData: {},
        addVisible: false,
        listQuery: {
          title: '',
          client: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        options: [],
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
      this.getData()
    },
    methods: {
      saveConfig() {
        updatePosterConfig({ ...this.topForm }).then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
          }
        })
      },
      getData() {
        getPosterConfig().then((res) => {
          this.topForm = res.data
        })
      },
      add() {
        this.addVisible = true
        this.rowData = {}
      },
      edit(row) {
        this.addVisible = true
        this.rowData = { ...row }
      },
      confirm(val) {
        if (val?.id) {
          updatePosterImg({ ...val, img: val.imgUrl }).then((res) => {
            if (res.code === 0) {
              this.addVisible = false
              this.$message.success(res.msg)
              this.fetchData()
            }
          })
        } else {
          addPosterImg({ ...val, img: val.imgUrl }).then((res) => {
            if (res.code === 0) {
              this.addVisible = false
              this.$message.success(res.msg)
              this.fetchData()
            }
          })
        }
      },
      del(row) {
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          delPosterImg(row.id).then(() => {
            this.fetchData()
          })
        })
      },
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        this.$router.push({
          path: '/merchant/device/manage',
          query: {
            id: row.id,
            shopName: row.shopName,
          },
        })
      },

      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        let params = {
          ...this.listQuery,
        }
        if (this.loading) return
        try {
          this.loading = true
          const {
            data: { records, total },
          } = await getPosterImg({ ...this.queryForm, ...params })
          this.tableData = records
          this.totalCount = +total
        } finally {
          this.loading = false
        }
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          title: '',
          client: '',
        }
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .form-table-container {
    padding: 20px;
    background-color: white;
  }
  .poster-setting {
    padding: 20px 20px 1px;
    background-color: white;
    margin-bottom: 20px;
  }
  .code-poster-management {
    background: $base-color-background !important;
    .global-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 737.5px;
      margin-bottom: 30px;
      .item-label {
        width: 80px;
        text-align: right;
      }
      .item-content {
        flex: 1;
        margin: 0 10px;
      }
    }
  }
  .box-card.el-card {
    border: none !important;
  }
</style>
