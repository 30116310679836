<template>
  <Dialog
    :height="dailog.height"
    :model-value="dailog.visible"
    :title="title + dailog.title"
    :visible="dailog.visible"
    :width="dailog.width"
    @handleCloseDialog="handleCloseDialog"
    @handleConfirm="handleCloseDialog"
  >
    <template #content>
      <el-form ref="form" label-width="100px" :model="formData" size="small">
        <div class="card-title">基本信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="账单编号" prop="id">
              {{ formData.id }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账单名称" prop="name">
              {{ formData.name }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同编号" prop="code">
              {{ formData.code }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户名称" prop="shopName">
              {{ formData.shopName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名称" prop="memberName">
              {{ formData.memberName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户手机" prop="memberPhone">
              {{ formData.memberPhone }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账单开始时间" prop="startDate">
              {{ formData.startDate }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账单结束时间" prop="endDate">
              {{ formData.endDate }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应支付日" prop="billDate">
              {{ formData.billDate }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实际支付日" prop="realPayTime">
              {{ formData.realPayTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户支付费用" prop="realAmount">
              {{ formData.realAmount }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平台服务费" prop="billServiceAmount">
              {{ formData.billServiceAmount }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户应收" prop="receivableAmount">
              {{ formData.receivableAmount }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider />
      <el-form ref="formPro" label-width="0" :model="listForm" size="small">
        <div class="card-title">账单明细</div>
      </el-form>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" sortable width="80">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="费用类型"
          prop="billSubjectName"
        />
        <el-table-column align="center" label="用量" prop="costMeterVal" />
        <el-table-column align="center" label="单价" prop="unitPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.unitPrice }}{{ scope.row.unitPriceName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="费用生效时间"
          prop="createTime"
        />
        <el-table-column
          align="center"
          label="金额(元)"
          prop="receivableAmount"
          sortable
        />
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="fetchData"
      />
    </template>
  </Dialog>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { getDetail } from '@/api/bill/list'
  export default {
    name: 'Detail',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        id: '',
        title: '',
        dailog: {
          height: 600,
          title: '订单详情',
          visible: false,
          width: '940px',
        },
        formData: {
          remark: '',
        },
        listForm: {
          createTime: '',
        },
        tableData: [],
        loading: false,

        listQuery: {
          id: '',
          pageNumber: 1,
          pageSize: 10,
        },
        totalCount: 0,
      }
    },
    created() {},
    methods: {
      showDetail(row) {
        console.log('showDetail>>>', row)
        this.formData = row
        this.dailog.visible = true
        this.listQuery.id = row.id

        this.fetchData()
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.$refs['form'].resetFields()
        this.formData = this.$options.data().formData
        this.listForm = this.$options.data().listForm
      },
      async fetchData() {
        this.loading = true
        getDetail(this.listQuery).then((res) => {
          if (res.code === 0) {
            console.log('getDetail>>>', res.data)
            this.tableData = res.data.list
            this.totalCount = res.data.totalRow
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep(.container) {
    padding: 0 20px;
  }
  .card-title {
    margin-bottom: 18px;
    font-size: 16px;
  }
</style>
