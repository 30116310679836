var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "code-poster-preview",
      attrs: { id: "code-poster-preview" },
    },
    [
      _c("div", { staticClass: "item-preview" }, [
        _vm._v(_vm._s(_vm.topForm.posterTitle)),
      ]),
      _c("div", { staticClass: "item-poster-img" }, [
        _c("img", { attrs: { alt: "", src: _vm.posterUrl } }),
      ]),
      _c("div", { staticClass: "item-code" }, [
        _c("div", { staticClass: "item-code-content" }, [
          _c("div", { staticClass: "item-code-title" }, [
            _vm._v(" " + _vm._s(_vm.posterImgData.publishName) + " "),
          ]),
          _c("div", { staticClass: "item-code-price" }, [
            _vm._v(
              " 租金：" + _vm._s(_vm.posterImgData.publishRentPrice) + " "
            ),
          ]),
          _c("div", { staticClass: "item-code-info" }, [
            _vm._v(" " + _vm._s(_vm.posterImgData.houseShape) + " "),
            _c("span", { staticStyle: { margin: "0 1px" } }, [_vm._v("|")]),
            _vm._v(" " + _vm._s(_vm.posterImgData.area) + "㎡ "),
            _vm.posterImgData.toward
              ? _c("span", { staticStyle: { margin: "0 1px" } }, [_vm._v("|")])
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.posterImgData.toward) + " "),
          ]),
          _c("div", { staticClass: "item-code-address" }, [
            _vm._v(" " + _vm._s(_vm.posterImgData.city) + " "),
            _c("span", { staticStyle: { margin: "0 1px" } }, [_vm._v("-")]),
            _vm._v(" " + _vm._s(_vm.posterImgData.district) + " "),
          ]),
        ]),
        _c("div", { staticClass: "item-code-img" }, [
          _c("img", { attrs: { alt: "", src: _vm.posterImgData.codeImg } }),
        ]),
      ]),
      _c("div", { staticClass: "item-footer" }, [
        _vm._v(_vm._s(_vm.topForm.posterText)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }