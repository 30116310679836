<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="费用类型" label-width="80px">
            <el-select
              v-model="listQuery.type"
              placeholder="账单类型"
              @change="handleChangeType"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="费用名称" label-width="80px">
            <el-input
              v-model="listQuery.name"
              clearable
              placeholder="费用名称"
            />
          </el-form-item>
          <el-form-item label="费用编码" label-width="80px">
            <el-input
              v-model="listQuery.code"
              clearable
              placeholder="费用编码"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-circle-plus" type="primary" @click="add">
          新增
        </el-button>
        <el-button icon="el-icon-d-caret" type="primary" @click="initRank">
          初始化排序
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="handleDelete">
          批量删除
        </el-button>
        <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
          刷新
        </el-button> -->
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="listQuery.code" placeholder="科目编码" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="listQuery.name" placeholder="科目名称" />
        </el-col>

        <el-col :span="6">
          <el-select v-model="listQuery.type" placeholder="账单类型">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>

        <el-button icon="el-icon-search" type="primary" @click="getList">
          查询
        </el-button>
      </el-row>

      <el-row style="margin-bottom: 10px; margin-top: 10px">
        <el-button icon="el-icon-circle-plus" type="primary" @click="add">
          新增
        </el-button>
        <el-button icon="el-icon-edit-outline" type="primary" @click="handEdit">
          修改
        </el-button>
        <el-button icon="el-icon-d-caret" type="primary" @click="initRank">
          初始化排序
        </el-button>
        <el-button icon="el-icon-refresh" type="primary" @click="refresh">
          刷新
        </el-button>
      </el-row> -->

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ (listQuery.pageNumber - 1) * listQuery.pageSize + $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="费用类型" prop="type" width="150">
        <template slot-scope="scope">
          <el-tag>{{ getTypeName(scope.row.type) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="费用名称" prop="name" />
      <el-table-column align="center" label="费用编码" prop="code" />
      <el-table-column align="center" label="费用收取方式" prop="collectType">
        <template slot-scope="scope">
          {{ getCollectTypeName(scope.row.collectType) }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        label="是否支持退款"
        prop="supportOriginalRefund"
        width="150"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.supportOriginalRefund"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否支持手工录入"
        prop="supportManuallyInput"
        width="150"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.supportManuallyInput"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否支持线下支付"
        prop="supportOfflinePay"
        width="150"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.supportOfflinePay"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否支持使用优惠券"
        prop="supportUseCoupon"
        width="150"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.supportUseCoupon"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否正数" prop="positive">
        <template #default="{ row }">
          <el-switch
            v-model="row.positive"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="手工录入和线下支付的状态"
        prop="inputPayStatus"
        width="200"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.inputPayStatus"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="是否是在创建社区时默认需要建立的科目关联"
        prop="isDefaultInputPay"
        width="350"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.isDefaultInputPay"
            active-color="#1890FF"
            :active-value="1"
            inactive-color="#A9A8A8"
            :inactive-value="0"
            @change="turn(row)"
          />
        </template>
      </el-table-column> -->
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="180"
      />
      <el-table-column
        align="center"
        label="费用说明"
        prop="illustration"
        show-overflow-tooltip=""
      />

      <el-table-column align="center" fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <!-- <el-button
            icon="el-icon-view"
            plain
            size="mini"
            type="primary"
            @click="handleDetail(scope.row)"
          >
            查看详情
          </el-button> -->

          <el-button
            circle
            :disabled="scope.$index === 0"
            icon="el-icon-arrow-up"
            size="small"
            @click="moveUpward(scope.row, scope.$index)"
          />
          <el-button
            circle
            :disabled="scope.$index + 1 == tableData.length"
            icon="el-icon-arrow-down"
            size="small"
            @click="moveDown(scope.row, scope.$index)"
          />
          <el-button
            icon="el-icon-edit-outline"
            type="text"
            @click="handEdit(scope.row)"
          >
            修改
          </el-button>
          <el-link
            type="danger"
            :underline="false"
            @click="handleDelete(scope.row)"
          >
            <i class="el-icon-delete el-icon--right"></i>
            删除
          </el-link>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.foot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="200px"
          :model="formData"
          :rules="rules"
          size="mini"
        >
          <el-form-item label="费用类型" prop="type">
            <el-select
              v-model="formData.type"
              placeholder="费用类型"
              style="width: 300px"
              @change="handleChangeType"
            >
              <template v-for="item in subjectTypeOptions">
                <el-option
                  v-if="item.value"
                  :key="item.value"
                  :disabled="item.disabled"
                  :label="item.label"
                  :value="Number(item.value)"
                />
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="费用名称" prop="name">
            <el-input
              v-model="formData.name"
              clearable
              :disabled="disabled"
              placeholder="请输入"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="费用编码" prop="code">
            <el-input
              v-model="formData.code"
              clearable
              :disabled="disabled"
              placeholder="请输入"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="费用单位" prop="unitName">
            <el-input
              v-model="formData.unitName"
              clearable
              :disabled="disabled"
              placeholder="请输入"
              style="max-width: 300px"
            />
          </el-form-item>
          <el-form-item label="费用收取方式" prop="collectType">
            <el-radio-group v-model="formData.collectType">
              <template v-for="item in collectTypeOptionsList">
                <el-radio
                  :key="item.value"
                  class="vab-background"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </template>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="业主端可使用" prop="status">
            <el-radio-group v-model="formData.status">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="费用说明" prop="illustration">
            <el-input
              v-model="formData.illustration"
              clearable
              :disabled="disabled"
              placeholder="请输入费用说明"
              style="max-width: 300px; margin-right: 10px"
            />
            <el-tooltip
              content="填写了费用说明将在房东端展示本样式内容"
              effect="dark"
              placement="top"
            >
              <vab-icon icon="question-line" />
            </el-tooltip>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="formData.remark"
              clearable
              :disabled="disabled"
              :min="0"
              placeholder="请输入"
              style="max-width: 300px; margin-right: 10px"
            />
          </el-form-item>
          <el-form-item label="输入最大值限制" prop="maxLimit">
            <el-input
              v-model="formData.maxLimit"
              clearable
              :disabled="disabled"
              placeholder="请输入"
              style="max-width: 300px; margin-right: 10px"
            />
          </el-form-item>
          <el-form-item label="序号" prop="sortRank">
            <el-input
              v-model="formData.sortRank"
              clearable
              :disabled="disabled"
              placeholder="请输入"
              style="max-width: 300px"
            />
          </el-form-item>

          <!-- <el-form-item label="支持退款" prop="supportOriginalRefund">
            <el-radio v-model="formData.supportOriginalRefund" :label="1">
              是
            </el-radio>
            <el-radio v-model="formData.supportOriginalRefund" :label="2">
              否
            </el-radio>
          </el-form-item>

          <el-form-item label="支持手工录入" prop="supportManuallyInput">
            <el-radio v-model="formData.supportManuallyInput" :label="1">
              是
            </el-radio>
            <el-radio v-model="formData.supportManuallyInput" :label="2">
              否
            </el-radio>
          </el-form-item>

          <el-form-item label="支持线下支付" prop="supportOfflinePay">
            <el-radio v-model="formData.supportOfflinePay" :label="1">
              是
            </el-radio>
            <el-radio v-model="formData.supportOfflinePay" :label="2">
              否
            </el-radio>
          </el-form-item>

          <el-form-item label="支持优惠券" prop="supportUseCoupon">
            <el-radio v-model="formData.supportUseCoupon" :label="1">
              是
            </el-radio>
            <el-radio v-model="formData.supportUseCoupon" :label="2">
              否
            </el-radio>
          </el-form-item>
          <el-form-item label="是否正数" prop="positive">
            <el-radio v-model="formData.positive" :label="1">是</el-radio>
            <el-radio v-model="formData.positive" :label="2">否</el-radio>
          </el-form-item>

          <el-form-item label="手工录入和线下支付的状态" prop="inputPayStatus">
            <el-radio v-model="formData.inputPayStatus" :label="1">是</el-radio>
            <el-radio v-model="formData.inputPayStatus" :label="2">否</el-radio>
          </el-form-item>

          <el-form-item
            label="是否是在创建社区时默认需要建立的科目关联"
            prop="isDefaultInputPay"
          >
            <el-radio v-model="formData.isDefaultInputPay" :label="1">
              是
            </el-radio>
            <el-radio v-model="formData.isDefaultInputPay" :label="2">
              否
            </el-radio>
          </el-form-item> -->
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import {
    subjectList,
    initRank,
    addSubject,
    editSubject,
    // moveUpward,
    // moveDown
    deleteSubject,
  } from '@/api/bill/bill'
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { isNumber } from '@/utils/validate'
  export default {
    name: 'BillSubject',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      const validateNumber = (rule, value, callback) => {
        if (value && !isNumber(value)) {
          callback(new Error('请输入数字'))
        } else {
          callback()
        }
      }
      return {
        disabled: false,
        rules: {
          type: [
            { required: true, message: '请选择费用类型', trigger: 'blur' },
          ],
          name: [
            { required: true, message: '请输入费用名称', trigger: 'blur' },
          ],
          code: [
            { required: true, message: '请输入费用编码', trigger: 'blur' },
          ],
          collectType: [
            { required: true, message: '请选择费用收取方式', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '请选择默认是否启用', trigger: 'blur' },
          ],
          supportOriginalRefund: [
            { required: true, message: '请选择是否支持退款', trigger: 'blur' },
          ],
          maxLimit: [
            { required: false, validator: validateNumber, trigger: 'blur' },
          ],
          sortRank: [
            { required: false, validator: validateNumber, trigger: 'blur' },
          ],
          supportManuallyInput: [
            {
              required: true,
              message: '请选择是否支持手工录入',
              trigger: 'blur',
            },
          ],
          supportOfflinePay: [
            {
              required: true,
              message: '请选择是否支持线下支付',
              trigger: 'blur',
            },
          ],
          supportUseCoupon: [
            {
              required: true,
              message: '请选择是否支持优惠券',
              trigger: 'blur',
            },
          ],
          positive: [
            { required: true, message: '请选择是否正数', trigger: 'blur' },
          ],
          inputPayStatus: [
            {
              required: true,
              message: '请选择是否手工录入和线下支付的状态',
              trigger: 'blur',
            },
          ],
          isDefaultInputPay: [
            {
              required: true,
              message: '请选择是否是在创建社区时默认需要建立的科目关联',
              trigger: 'blur',
            },
          ],
        },
        dailog: {
          height: 480,
          title: '',
          visible: false,
          width: '800px',
          foot: true,
        },
        formData: {
          type: undefined,
          name: '',
          code: '',
          unitName: '',
          collectType: '',
          status: 1,
          maxLimit: undefined,
          sortRank: undefined,
          remark: '',
          illustration: '',
          supportOriginalRefund: 1,
          supportManuallyInput: 1,
          supportOfflinePay: 1,
          supportUseCoupon: 1,
          positive: 1,
          inputPayStatus: 1,
          isDefaultInputPay: 1,
        },

        ddd: 'zc',
        typeOptions: [
          //1-租赁费 2-押金 3-能源费 4-服务费 5-手续费 99 其他费用
          {
            value: '',
            label: '账单类型',
          },
          {
            value: '1',
            label: '租赁费',
          },
          {
            value: '2',
            label: '押金',
          },
          {
            value: '3',
            label: '能源费',
          },
          {
            value: '4',
            label: '服务费',
          },
          {
            value: '5',
            label: '手续费',
          },
          {
            value: '99',
            label: '其他费用',
          },
        ],
        options: [
          {
            value: '',
            label: '签约状态',
          },
          {
            value: '0',
            label: '未签约',
          },
          {
            value: '1',
            label: '已签约',
          },
        ],
        collectTypeOptions: [
          {
            label: '押金',
            value: 'deposit',
          },
          {
            label: '按月收取',
            value: 'month',
          },
          {
            label: '一次性',
            value: 'disposable',
          },
        ],
        value: '',
        input: '',
        listQuery: {
          name: '',
          code: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        tableData1: [
          {
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄',
          },
        ],
        loading: false,
        selection: [],
        selected: {},
      }
    },
    computed: {
      subjectTypeOptions() {
        return this.typeOptions.map((item) => ({
          ...item,
          disabled: ['1', '3'].includes(String(item.value)),
        }))
      },
      collectTypeOptionsList() {
        if (this.formData.type) {
          const isDeposit = this.formData.type == '2'
          return this.collectTypeOptions.filter((item) =>
            isDeposit ? item.value === 'deposit' : item.value !== 'deposit'
          )
        }
        return this.collectTypeOptions
      },
    },
    created() {
      this.getList()
    },
    methods: {
      handleChangeType(e) {
        if (e == '2') {
          this.formData.collectType = 'deposit'
        } else {
          this.formData.collectType = 'month'
        }
      },
      getTypeName(type) {
        return this.typeOptions.find((item) => item.value == type).label
      },
      getCollectTypeName(type) {
        return this.collectTypeOptions.find((item) => item.value == type).label
      },
      searchReset() {
        this.listQuery = this.$options.data().listQuery
        this.getList()
      },
      //查询
      getList() {
        console.log(this.listQuery)
        this.loading = true
        subjectList(this.listQuery).then((res) => {
          if (res.code === 0) {
            console.log('res.data111', res.data.records)
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      refresh() {
        this.listQuery.pageNumber = 1
        this.getList()
      },
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        console.log('单选===', selection)
        console.log('单选===', row)
        this.selected = row
      },

      //编辑
      handleDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '账单科目详细信息查看'
        this.dailog.foot = false
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      handleDelete(row) {
        if (row && row.id) {
          this.$baseConfirm(
            '删除后业主端无法使用该费用，确认删除吗?',
            null,
            async () => {
              await deleteSubject({ ids: row.id })
              this.$baseMessage(
                '删除成功',
                'success',
                'vab-hey-message-success'
              )
              this.getList()
            }
          )
        } else {
          if (this.selection.length > 0) {
            const ids = this.selection.map((item) => item.id).join()
            this.$baseConfirm(
              '删除后业主端无法使用该费用，确认删除吗?',
              null,
              async () => {
                const { msg } = await deleteSubject({ ids: ids })
                this.$baseMessage(msg, 'success', 'vab-hey-message-success')
                this.getList()
              }
            )
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      add() {
        this.formData = this.$options.data().formData
        this.selected = {}
        this.dailog.visible = true
        this.dailog.title = '新增账单科目详细信息'
        this.dailog.foot = true
      },
      handEdit(row) {
        // if (this.selection.length == 0) {
        //   this.$message({
        //     message: '请至少选择一行数据',
        //     type: 'warning',
        //   })
        //   return false
        // }
        this.selected = row
        this.dailog.visible = true
        this.dailog.foot = true
        this.dailog.title = '修改账单科目详细信息'
        this.$nextTick(() => {
          this.formData = JSON.parse(JSON.stringify(row))
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        console.log(this.formData, 588)
        this.$refs['formData'].validate((valid) => {
          console.log(valid)
          if (valid) {
            const { maxLimit, sortRank } = this.formData
            if (this.selected.id) {
              editSubject({
                ...this.formData,
                maxLimit: maxLimit || '',
                sortRank: sortRank || '',
              }).then((res) => {
                if (res.code == 0) {
                  this.dailog.visible = false
                  this.selected = {}
                  this.formData = this.$options.data().formData
                  this.getList()
                }
              })
            } else {
              addSubject(this.formData).then((res) => {
                if (res.code == 0) {
                  this.dailog.visible = false
                  this.getList()
                }
              })
            }
          }
        })
      },
      //添加商品信息
      addInfo() {},
      //重置
      resetQuery() {},
      handleRead() {},
      //初始化排序
      initRank() {
        initRank().then((res) => {
          console.log('initRank', res)
          this.getList()
        })
      },
      //上移
      moveUpward(row, index) {
        if (index > 0) {
          let upData = this.tableData[index - 1]
          this.tableData.splice(index - 1, 1)
          this.tableData.splice(index, 0, upData)
        } else {
          this.$message({
            message: '已经是第一条，上移失败',
            type: 'warning',
          })
        }
      },

      //下移
      moveDown(row, index) {
        if (index + 1 == this.tableData.length) {
          this.$message({
            message: '已经是最后一条，下移失败',
            type: 'warning',
          })
        } else {
          let downData = this.tableData[index + 1]
          this.tableData.splice(index + 1, 1)
          this.tableData.splice(index, 0, downData)
        }
      },
      turn(row) {
        console.log(row, 488)
        this.$confirm('是否切换状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            editSubject(row).then((res) => {
              if (res.code == 0) {
                this.dailog.visible = false
                this.selected = {}
                this.formData = {}
                this.getList()
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                })
              }
            })

            // row.enable = row.enable
            // console.log(row.enable, 496)
            // this.$message({
            //   type: 'success',
            //   message: '删除成功!',
            // })
          })
          .catch(() => {
            row.enable = !row.enable
            // console.log(row.enable, 504)
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除',
            // })
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
</style>
