var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: {
      height: _vm.dailog.height,
      "model-value": _vm.dailog.visible,
      title: _vm.title + _vm.dailog.title,
      visible: _vm.dailog.visible,
      width: _vm.dailog.width,
    },
    on: {
      handleCloseDialog: _vm.handleCloseDialog,
      handleConfirm: _vm.handleCloseDialog,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "100px",
                  model: _vm.formData,
                  size: "small",
                },
              },
              [
                _c("div", { staticClass: "card-title" }, [_vm._v("基本信息")]),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单编号", prop: "id" } },
                          [_vm._v(" " + _vm._s(_vm.formData.id) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单名称", prop: "name" } },
                          [_vm._v(" " + _vm._s(_vm.formData.name) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "合同编号", prop: "code" } },
                          [_vm._v(" " + _vm._s(_vm.formData.code) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商户名称", prop: "shopName" } },
                          [_vm._v(" " + _vm._s(_vm.formData.shopName) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户名称", prop: "memberName" } },
                          [_vm._v(" " + _vm._s(_vm.formData.memberName) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "用户手机", prop: "memberPhone" } },
                          [_vm._v(" " + _vm._s(_vm.formData.memberPhone) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "账单开始时间", prop: "startDate" },
                          },
                          [_vm._v(" " + _vm._s(_vm.formData.startDate) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单结束时间", prop: "endDate" } },
                          [_vm._v(" " + _vm._s(_vm.formData.endDate) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "应支付日", prop: "billDate" } },
                          [_vm._v(" " + _vm._s(_vm.formData.billDate) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "实际支付日", prop: "realPayTime" },
                          },
                          [_vm._v(" " + _vm._s(_vm.formData.realPayTime) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "用户支付费用",
                              prop: "realAmount",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.formData.realAmount) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "平台服务费",
                              prop: "billServiceAmount",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.formData.billServiceAmount) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "商户应收",
                              prop: "receivableAmount",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.formData.receivableAmount) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-divider"),
            _c(
              "el-form",
              {
                ref: "formPro",
                attrs: {
                  "label-width": "0",
                  model: _vm.listForm,
                  size: "small",
                },
              },
              [_c("div", { staticClass: "card-title" }, [_vm._v("账单明细")])]
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { border: "", data: _vm.tableData, stripe: "" },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("el-image", {
                          staticClass: "vab-data-empty",
                          attrs: {
                            src: require("@/assets/empty_images/data_empty.png"),
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "序号",
                    sortable: "",
                    width: "80",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ $index }) {
                        return [_vm._v(" " + _vm._s($index + 1) + " ")]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "费用类型",
                    prop: "billSubjectName",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "用量",
                    prop: "costMeterVal",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "单价", prop: "unitPrice" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.unitPrice) +
                                _vm._s(scope.row.unitPriceName)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "费用生效时间",
                    prop: "createTime",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "金额(元)",
                    prop: "receivableAmount",
                    sortable: "",
                  },
                }),
              ],
              1
            ),
            _c("Pagination", {
              attrs: {
                limit: _vm.listQuery.pageSize,
                page: _vm.listQuery.pageNumber,
                "total-count": _vm.totalCount,
              },
              on: {
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageNumber", $event)
                },
                pagination: _vm.fetchData,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }