<template>
  <div id="code-poster-preview" class="code-poster-preview">
    <div class="item-preview">{{ topForm.posterTitle }}</div>
    <div class="item-poster-img">
      <img alt="" :src="posterUrl" />
    </div>
    <div class="item-code">
      <div class="item-code-content">
        <div class="item-code-title">
          {{ posterImgData.publishName }}
        </div>
        <div class="item-code-price">
          租金：{{ posterImgData.publishRentPrice }}
        </div>
        <div class="item-code-info">
          {{ posterImgData.houseShape }}
          <span style="margin: 0 1px">|</span>
          {{ posterImgData.area }}㎡
          <span v-if="posterImgData.toward" style="margin: 0 1px">|</span>
          {{ posterImgData.toward }}
        </div>
        <div class="item-code-address">
          {{ posterImgData.city }}
          <span style="margin: 0 1px">-</span>
          {{ posterImgData.district }}
        </div>
      </div>
      <div class="item-code-img">
        <img alt="" :src="posterImgData.codeImg" />
      </div>
    </div>
    <div class="item-footer">{{ topForm.posterText }}</div>
  </div>
</template>
<script>
  import html2canvas from 'html2canvas'
  import { downloadByBlob } from '@/utils/index'
  import { getPosterConfig } from '@/api/basicssetting/newIndex'
  export default {
    name: '',
    props: {
      posterImgData: {
        type: Object,
        default: () => {
          return {}
        },
      },
      posterUrl: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        topForm: {
          posterTitle: '',
          posterText: '',
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        getPosterConfig().then((res) => {
          this.topForm = res.data
        })
      },
      down() {
        return new Promise((resolve) => {
          html2canvas(document.getElementById('code-poster-preview'), {
            useCORS: true,
            scale: 2,
            dpi: 800,
          }).then((res) => {
            const showImgUrl = res.toDataURL('image/png') // 此时就得到了dom元素转成了base64的图片
            downloadByBlob(
              showImgUrl,
              `${this.posterImgData.communityName}${this.posterImgData.buildingName}${this.posterImgData.houseName}`
            )
            resolve(showImgUrl)
          })
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .code-poster-preview {
    width: 316px;
    height: 417px;
    padding: 0 19px;
    background: #f2f1f6;
    border-radius: 18px;
    box-sizing: border-box;
    .item-preview {
      height: 60px;
      line-height: 60px;
      font-weight: 600;
      font-size: 16px;
      color: #1f2d3d;
      letter-spacing: 0;
      text-align: center;
    }
    .item-poster-img {
      width: 278px;
      height: 139px;
      img {
        width: 100%;
        height: 100%;

        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }
    }
    .item-code {
      display: flex;
      background-color: #fff;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 10px;
      box-shadow: 0 40px 81px 0 rgba(50, 50, 51, 0.12);
      margin-bottom: 21px;
      .item-code-img {
        width: 118px;
        height: 118px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-code-content {
        flex: 1;
        .item-code-title {
          height: 19px;
          margin-top: 10px;
          font-weight: 600;
          font-size: 13.48px;
          color: #1f2d3d;
          letter-spacing: 0.1px;
        }
        .item-code-price {
          color: rgba(239, 52, 52, 1);
          margin-top: 9px;
          font-size: 15.17px;
          font-weight: 700;
          line-height: 15.17px;
          letter-spacing: 0;
          span {
            color: rgba(239, 52, 52, 1);
            font-size: 12px;
            font-weight: 700;
            line-height: 12px;
            letter-spacing: 0;
          }
        }
        .item-code-info,
        .item-code-address {
          height: 17px;
          font-weight: 400;
          font-size: 12px;
          color: #5e6d82;
          letter-spacing: 0.08px;
        }
        .item-code-info {
          margin-top: 13px;
          margin-bottom: 6px;
        }
      }
    }
    .item-footer {
      font-weight: 400;
      font-size: 11px;
      color: #6e7e95;
      letter-spacing: 0;
      text-align: center;
      line-height: 16px;
    }
  }
</style>
