<template>
  <el-dialog
    :before-close="handleClose"
    :title="rowData?.id ? '编辑/二维码海报图' : '新增/二维码海报图'"
    :visible="addVisible"
    width="680px"
  >
    <el-form
      ref="ruleForm"
      label-width="100px"
      :model="ruleForm"
      :rules="rules"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户端" prop="client">
            <el-select v-model="ruleForm.client" placeholder="请选择用户端">
              <el-option label="管理后台" value="PLATFORM" />
              <el-option label="房东端(Web)" value="SHOP" />
              <el-option label="租户端" value="MEMBER" />
              <el-option
                label="房东端(小程序)"
                value="SHOP_WECHAT_SMALL_ROUTINE"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否启用" prop="status">
            <el-select v-model="ruleForm.status" placeholder="请选择">
              <el-option label="是" value="ENABLED" />
              <el-option label="否" value="DISABLED" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="海报名称" prop="title">
            <el-input
              v-model="ruleForm.title"
              maxlength="20"
              placeholder="请输入海报名称"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="add-code-poster">
        <el-col :span="24">
          <el-form-item label="上传/更换" prop="imgUrl">
            <JMUpload
              v-if="ruleForm.img != ''"
              v-model="ruleForm.img"
              :multiple="false"
              :number="1"
              @change="handleChange"
            />
            <span class="png-tip">
              图片尺寸: 660x330PX，或者宽高比2:1， 图片大小请限制在10MB内
            </span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import JMUpload from '@/components/jm-upload'
  export default {
    name: '',
    components: { JMUpload },
    props: {
      addVisible: {
        type: Boolean,
        default: () => {
          return false
        },
      },
      rowData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data() {
      return {
        ruleForm: {
          client: '',
          status: '',
          title: '',
          imgUrl: '',
          img: undefined,
        },
        rules: {
          title: [
            { required: true, message: '请输入海报名称', trigger: 'blur' },
          ],
          status: [
            { required: true, message: '请选择启用状态', trigger: 'change' },
          ],
          client: [
            { required: true, message: '请选择用户端', trigger: 'change' },
          ],
          imgUrl: [
            {
              required: true,
              message: '请上传图片',
              trigger: ['change', 'blur'],
            },
          ],
        },
      }
    },
    created() {
      this.ruleForm = {
        ...this.ruleForm,
        ...this.rowData,
        imgUrl: this.rowData.img,
      }
    },
    methods: {
      handleChange(value) {
        this.ruleForm.imgUrl = value
      },
      handleClose() {
        this.$emit('update:addVisible', false)
      },
      handleConfirm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$emit('confirm', this.ruleForm)
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  .add-code-poster {
    .png-tip {
      color: #ccc;
      font-size: 12px;
      font-weight: 400;
      line-height: 0;
      letter-spacing: 0;
      text-align: left;
    }
  }
</style>
