<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="100px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="合同编号">
            <el-input v-model="listQuery.code" placeholder="合同编号" />
          </el-form-item>
          <el-form-item label="商户名称">
            <el-input v-model="listQuery.shopName" placeholder="商户名称" />
          </el-form-item>
          <el-form-item label="用户名称">
            <el-input v-model="listQuery.memberName" placeholder="用户名称" />
          </el-form-item>
          <el-form-item label="账单名称">
            <el-input v-model="listQuery.name" placeholder="账单名称" />
          </el-form-item>
          <el-form-item label="账单编号">
            <el-input v-model="listQuery.billId" placeholder="账单编号" />
          </el-form-item>
          <el-form-item label="账单状态">
            <el-select v-model="listQuery.billStatus" placeholder="账单状态">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="支付日期">
            <el-date-picker
              v-model="payTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item label="应支付生效日">
            <el-date-picker
              v-model="shouldPayTime"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              type="primary"
              @click="searchReset"
            >
              重置
            </el-button>
            <!-- <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <!-- <el-table-column align="center" type="selection" width="50" /> -->
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账单编号"
        prop="billId"
        width="180"
      />
      <el-table-column
        align="center"
        label="合同编号"
        prop="code"
        width="200"
      />
      <el-table-column
        align="center"
        label="账单名称"
        prop="name"
        width="200"
      />
      <el-table-column
        align="center"
        label="商户名称"
        prop="shopName"
        width="180"
      />
      <el-table-column
        align="center"
        label="用户名称"
        prop="memberName"
        width="180"
      />
      <!-- <el-table-column
        align="center"
        label="账单周期"
        prop="billCycle"
        width="180"
      /> -->
      <el-table-column
        align="center"
        label="应支付日"
        prop="billDate"
        width="180"
      />
      <el-table-column
        align="center"
        label="账单状态"
        prop="billStatus"
        width="150"
      >
        <template v-if="statusFormat(scope.row.status)" slot-scope="scope">
          <el-tag v-if="scope.row.status === 10" type="success">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 20">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 30">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 40" type="info">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 50" type="info">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
          <el-tag v-if="scope.row.status === 60" type="info">
            {{ statusFormat(scope.row.status) }}
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="原价" prop="xxxx" width="180" /> -->
      <el-table-column
        align="center"
        label="账单金额"
        prop="receivableAmount"
        width="180"
      />
      <el-table-column
        align="center"
        label="支付状态"
        prop="payStatus"
        width="150"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.payStatus === 1" type="success">
            {{ payStatusFormat(scope.row.payStatus) }}
          </el-tag>
          <el-tag v-if="scope.row.payStatus === 0">
            {{ payStatusFormat(scope.row.payStatus) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="支付方式"
        prop="paymentWay"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status === 10">
            {{ paymentWayFormat(scope.row.paymentWay) }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        label="退款金额"
        prop="refundAmount"
        width="180"
      />
      <el-table-column
        align="center"
        label="申请退款原因"
        prop="refundReason"
        width="180"
      />
      <el-table-column
        align="center"
        label="退款状态"
        prop="refundStatus"
        width="150"
      >
        <template slot-scope="scope">
          <span>{{ refundStatusFormat(scope.row.refundStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="退款时间"
        prop="refundTime"
        width="180"
      />
      <el-table-column
        align="center"
        label="拒绝原因"
        prop="rejectReason"
        width="180"
      /> -->
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        width="180"
      />

      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />
    <Detail ref="detail" />
  </div>
</template>

<script>
  import { getList } from '@/api/bill/list'
  import Pagination from '@/components/pagination.vue'
  import Detail from './components/Detail.vue'
  const dayjs = require('dayjs')
  export default {
    name: 'BillList',
    components: {
      Pagination,
      Detail,
    },
    data() {
      return {
        disabled: false,

        dailog: {
          height: 700,
          title: '',
          visible: false,
          width: '1000px',
        },
        formData: {
          code: '',
        },

        ddd: 'zc',

        options: [
          {
            value: '',
            label: '请选择账单状态',
          },
          {
            value: 10,
            label: '已支付',
          },
          {
            value: 20,
            label: '待回款',
          },
          {
            value: 30,
            label: '待生效',
          },
          {
            value: 40,
            label: '已逾期',
          },
          {
            value: 50,
            label: '已作废',
          },
        ],
        value: '',
        input: '',
        payTime: null,
        shouldPayTime: null,
        listQuery: {
          contractId: '',
          buildingName: '',
          memberName: '',
          name: '',
          billStatus: '',
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
      }
    },
    created() {
      if (this.$route.query.billStatus) {
        this.listQuery.billStatus = Number(this.$route.query.billStatus)
      }
      this.getList()
    },
    methods: {
      searchReset() {
        this.payTime = null
        this.shouldPayTime = null
        this.listQuery = {}
        this.getList()
      },
      //查询
      getList() {
        this.loading = true
        let params = {
          ...this.listQuery,
          billDateStartTime: this.shouldPayTime
            ? dayjs(this.shouldPayTime[0]).format('YYYY-MM-DD')
            : undefined,
          billDateEndTime: this.shouldPayTime
            ? dayjs(this.shouldPayTime[1]).format('YYYY-MM-DD')
            : undefined,
          realPayTimeStartTime: this.payTime
            ? dayjs(this.payTime[0]).format('YYYY-MM-DD')
            : undefined,
          realPayTimeEndTime: this.payTime
            ? dayjs(this.payTime[1]).format('YYYY-MM-DD')
            : undefined,
        }

        getList(params).then((res) => {
          if (res.code === 0) {
            // console.log('res>>>', res.data)
            this.tableData = res.data.records
            this.totalCount = +res.data.total
            this.loading = false
          } else {
            this.loading = false
          }
        })
      },
      //表格选择
      selectRow(selection, row) {
        this.selection = selection
        // console.log('单选===', selection)
        console.log('单选===', row)
      },
      //查看详情
      handleDetail(row) {
        let id = row.id
        this.$router.push({
          path: '/admin/bill/detail',
          query: { id },
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        // console.log(this.formData, 588)
        this.$refs['formData'].validate((valid) => {
          // console.log(valid)
          if (valid) {
            // menuSave(this.formData).then((res) => {
            //   this.commonMessage(res)
            // })
          }
        })
      },
      // 状态赋值
      readStatusFormat(row) {
        // console.log('单选===', row)
        let state = row.readStatus
        if (state === 0) {
          return '否'
        } else if (state === 1) {
          return '是'
        }
      },
      refundStatusFormat(status) {
        // console.log('单选===', row)
        // let state = row.readStatus
        if (status === 0) {
          return '未退款'
        } else if (status === 1) {
          return '待处理'
        } else if (status === 2) {
          return '已同意'
        } else if (status === 3) {
          return '退款失败（拒绝）'
        } else if (status === 4) {
          return '已取消'
        } else if (status === 5) {
          return '退款成功'
        }
      },
      payStatusFormat(status) {
        if (status === 0) {
          return '未付款'
        } else if (status === 1) {
          return '已支付'
        }
      },
      paymentWayFormat(status) {
        if (status === 1) {
          return '微信支付'
        } else if (status === 10) {
          return '现金'
        } else if (status === 11) {
          return '微信转账'
        } else if (status === 12) {
          return '支付宝转账'
        } else if (status === 13) {
          return '银联转账'
        } else if (status === 14) {
          return '刷卡支付'
        } else if (status === 99) {
          return '其他'
        }
      },
      statusFormat(status) {
        if (status === 10) {
          return '已支付'
        } else if (status === 20) {
          return '待回款'
        } else if (status === 30) {
          return '待生效'
        } else if (status === 40) {
          return '已逾期'
        } else if (status === 50) {
          return '已作废'
        } else if (status === 60) {
          return '已退款'
        }
      },
      //刷新
      refresh() {
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-dialog .el-form-item__label {
    width: 150px !important;
  }
</style>
