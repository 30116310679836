<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="分享标题">
            <el-input
              v-model="listQuery.shareTitle"
              clearable
              placeholder="分享标题"
            />
          </el-form-item>
          <el-form-item label="分享名称" label-width="80px">
            <el-input
              v-model="listQuery.resourceName"
              clearable
              placeholder="分享名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card">
      <el-row style="margin-bottom: 10px">
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="showDialog"
        >
          新增
        </el-button>
      </el-row> -->
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="分享图标">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.shareIcon"
            :preview-src-list="[scope.row.shareIcon]"
            :src="scope.row.shareIcon"
            style="width: 50px; height: 50px"
          />
          <el-image
            v-else
            :preview-src-list="[defaultImg]"
            :src="defaultImg"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="页面名称"
        prop="resourceName"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="页面标识"
        prop="resourceIndex"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="分享标题"
        prop="shareTitle"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column align="center" label="分享内容" prop="shareContent" />
      <el-table-column
        align="center"
        label="WEB分享链接URL"
        prop="shareUrl"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="小程序分享页面"
        prop="shareWxaUrl"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="所属应用"
        prop="belongSystem"
        show-overflow-tooltip
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.belongSystem === '1'">{{ appName }}客户端</span>
          <span v-if="scope.row.belongSystem === '2'">{{ appName }}房东端</span>
          <span v-if="scope.row.belongSystem === '3'">{{ appName }}H5</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否发布">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isPublic">
            {{ fotmat(scope.row.isPublic, isPublicList) }}
          </el-tag>
          <el-tag v-else type="warning">
            {{ fotmat(scope.row.isPublic, isPublicList) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createTime"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 1)"
          >
            详情/编辑
          </el-button>
          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="showfoot"
      :title="dailog.title"
      :top="dailog.top"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="160px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-col :span="24">
            <el-form-item label="页面名称" prop="resourceName">
              <el-input
                v-model="formData.resourceName"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="页面标识" prop="resourceIndex">
              <el-input
                v-model="formData.resourceIndex"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分享标题" prop="shareTitle">
              <el-input
                v-model="formData.shareTitle"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分享内容" prop="shareContent">
              <el-input
                v-model="formData.shareContent"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="WEB分享链接URL" prop="shareUrl">
              <el-input
                v-model="formData.shareUrl"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="小程序分享页面Page" prop="shareWxaUrl">
              <el-input
                v-model="formData.shareWxaUrl"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属应用" prop="belongSystem">
              <el-select
                v-model="formData.belongSystem"
                clearable
                :disabled="disabled"
                filterable
                style="width: 300px; max-width: 300px"
              >
                <el-option
                  v-for="item in belongSystemList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否发布" prop="isPublic">
              <el-radio-group v-model="formData.isPublic">
                <el-radio
                  v-for="item in isPublicList"
                  :key="item.value"
                  :disabled="disabled"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分享图标" prop="shareIcon">
              <!-- <JUpload
                v-if="formData.shareIcon != ''"
                v-model="formData.shareIcon"
                :multiple="false"
                :number="1"
                @change="handleChange"
              /> -->
              <el-image
                v-if="formData.shareIcon"
                :preview-src-list="[formData.shareIcon]"
                :src="formData.shareIcon"
                style="width: 150px; height: 150px"
              />
              <el-upload
                :action="domain"
                :before-upload="beforeUpload"
                :data="QiniuData"
                :file-list="fileList"
                :limit="1"
                multiple
                :on-error="uploadError"
                :on-exceed="onExceed"
                :on-remove="onRemove"
                :on-success="uploadSuccess"
              >
                <el-button v-if="showfoot" size="small" type="primary">
                  点击上传
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  // import JUpload from '@/components/j-upload'
  import {
    wxshareList,
    wxshareDel,
    wxshareAdd,
    wxshareUpdate,
  } from '@/api/basicssetting'
  import { getUploadToken } from '@/api/common/index'
  const uploadUrlConf = {
    'qiniu.region.z0': '//upload.qiniup.com',
    'qiniu.region.z2': '//upload-z2.qiniup.com',
    'qiniu.region.z1': '//upload-z1.qiniup.com',
  }
  export default {
    name: 'Wxshare',
    components: {
      // JUpload,
      Pagination,
      Dialog,
    },
    data() {
      return {
        appName: process.env.VUE_APP_BASE_TITLE,
        defaultImg: require('@/assets/default.jpg'),
        disabled: false,
        dailog: {
          height: null,
          title: '微信分享设置',
          visible: false,
          width: '600px',
          top: '0vh',
        },
        showfoot: true,
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          shareTitle: '',
          resourceName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        rules: {
          resourceName: { required: true, message: '请输入', trigger: 'blur' },
          shareTitle: { required: true, message: '请输入', trigger: 'blur' },
          shareContent: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
          shareUrl: { required: false, message: '请输入', trigger: 'blur' },
          shareWxaUrl: { required: true, message: '请输入', trigger: 'blur' },
          belongSystem: {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
          shareIcon: { required: true, message: '请选择', trigger: 'blur' },
          resourceIndex: {
            required: true,
            message: '请输入',
            trigger: 'blur',
          },
        },
        isPublicList: [
          {
            label: '否',
            value: 0,
          },
          {
            label: '是',
            value: 1,
          },
        ],
        QiniuData: {
          key: '', //图片名字处理
          token: '', //七牛云token
          data: {},
        },
        domain: '', // 七牛云的上传地址（华东区）
        uploadUrl: '', //提交到后台图片地址
        fileList: [],
        urlList: [],
        belongSystemList: [
          {
            label: process.env.VUE_APP_BASE_TITLE + '租客端',
            value: '1',
          },
          {
            label: process.env.VUE_APP_BASE_TITLE + '房东端',
            value: '2',
          },
          {
            label: process.env.VUE_APP_BASE_TITLE + '公众号H5',
            value: '3',
          },
        ],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { records, total },
        } = await wxshareList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      // 0详情  1编辑
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.showfoot = false
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await wxshareUpdate(this.formData)
            } else {
              // 新增
              await wxshareAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await wxshareDel(params)
          await this.getList()
        })
      },
      handleDelete(item) {
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const params = {
            ids: [item.id],
          }
          await wxshareDel(params)
          await this.getList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.showfoot = true
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          shareTitle: '',
          resourceName: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        return res[0].label
      },
      async beforeUpload(file) {
        const fileName = file.name || file.path
        let ext = fileName.substring(
          fileName.lastIndexOf('.') + 1,
          fileName.length
        )
        let res = await getUploadToken({
          extension: ext,
        })
        if (res.code === 0) {
          this.domain = uploadUrlConf[res.data.region]
          this.QiniuData = {
            data: file,
            token: res.data.token,
            key: res.data.key,
          }
          this.uploadUrl = res.data.url
          return true
        }
        return false
      },
      handleChange(value) {
        console.log('value', value)
        this.formData.shareIcon = value
      },
      uploadSuccess(response, file) {
        this.urlList.push({
          uid: file.uid,
          url: this.uploadUrl,
        })
        this.formData.shareIcon = this.uploadUrl
        console.log('上传成功', this.formData.shareIcon)
        this.uploadUrl = ''
      },
      // 上传失败
      uploadError() {
        this.uploadUrl = ''
        this.$message({
          message: '上传出错，请重试！',
          type: 'error',
          center: true,
        })
      },
      onExceed() {
        this.$message({
          message: '最多可上传1张图片',
          type: 'error',
          center: true,
        })
      },
      onRemove(file) {
        const arr = this.urlList.filter((o) => {
          return o.uid !== file.uid
        })
        this.urlList = arr
        this.formData.shareIcon = ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-card.is-always-shadow {
    box-shadow: none !important;
  }
  .box-card.el-card {
    border: none !important;
  }
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
</style>
