<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="名称">
            <el-input v-model="listQuery.name" clearable placeholder="名称" />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              @click="getParamSettingList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <el-card class="box-card"> -->
    <!-- <el-row style="margin-bottom: 10px">
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
        <el-button
          icon="el-icon-plus"
          type="primary"
          @click="showDialog"
        >
          新增
        </el-button>
      </el-row> -->
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @select="selectRow"
      @select-all="selectRow"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="名称" prop="name" />
      <el-table-column align="left" label="默认值">
        <template slot-scope="scope">
          {{ defaultValueFormat(scope.row.defaultValue) }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="类型">
        <template slot-scope="scope">
          {{ typeFormat(scope.row.type) }}
        </template>
      </el-table-column>
      <!-- <el-table-column align="left" label="参数编码" prop="code" /> -->
      <el-table-column align="left" label="类型">
        <template slot-scope="scope">
          {{ codeFormat(scope.row.code) }}
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 0)"
          >
            详情
          </el-button> -->
          <el-button
            size="mini"
            type="text"
            @click="handleDetail(scope.row, 1)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="getParamSettingList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          :rules="rules"
          size="small"
        >
          <el-col :span="24">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="formData.name"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
                type="text"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="类型" prop="type">
              <el-select v-model="formData.type" clearable :disabled="disabled">
                <el-option
                  v-for="(item, key) in typeListData"
                  :key="key"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="默认值" prop="defaultValue">
              <el-radio-group v-model="formData.defaultValue">
                <el-radio
                  v-for="item in checkedList"
                  :key="item.value"
                  :disabled="disabled"
                  :label="item.value"
                >
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="参数编码" prop="code">
              <el-select v-model="formData.code" :disabled="disabled">
                <el-option
                  v-for="(item, key) in parameterCodeOptions"
                  :key="key"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <!-- <el-input
                v-model="formData.code"
                clearable
                disabled
                style="max-width: 300px"
                type="text"
              /> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                v-model="formData.remark"
                clearable
                :disabled="disabled"
                style="max-width: 300px"
                type="text"
              />
            </el-form-item>
          </el-col>
        </el-form>
      </template>
    </Dialog>
    <!-- </el-card> -->
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import {
    paramSettingList,
    paramSettingAdd,
    // paramSettingDetail,
    paramSettingUpdate,
    getParamMap,
    billDel,
  } from '@/api/basicssetting'
  export default {
    name: 'ParamsSetting',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dailog: {
          title: '商户参数设置',
          visible: false,
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          name: '',
        },
        parameterCodeOptions: [],
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        rules: {
          name: { required: true, message: '请输入', trigger: 'blur' },
          type: { required: true, message: '请选择', trigger: 'change' },
          defaultValue: {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
          code: { required: true, message: '请选择', trigger: 'change' },
        },
        checkedList: [
          {
            label: '开启',
            value: 1,
          },
          {
            label: '关闭',
            value: 0,
          },
        ],
        shopListData: [],
        typeListData: [
          {
            label: '开关',
            value: 1,
          },
          {
            label: '数值',
            value: 2,
          },
          {
            label: '分类',
            value: 3,
          },
        ],
      }
    },
    created() {
      this.getParamSettingList()
      this.getParameterCodeOptions()
    },
    methods: {
      // 全局参数编码查询
      async getParameterCodeOptions() {
        this.loading = true
        const { data } = await getParamMap()
        this.loading = false
        this.formData.code = data[0].name
        this.parameterCodeOptions = data
      },
      //查询
      async getParamSettingList() {
        this.loading = true
        const {
          data: { records, total },
        } = await paramSettingList(this.listQuery)
        this.loading = false
        this.tableData = records
        this.totalCount = +total
      },
      //编辑,查看
      handleDetail(row) {
        this.dailog.visible = true
        this.formData = JSON.parse(JSON.stringify(row))
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await paramSettingUpdate(this.formData)
            } else {
              // 新增
              await paramSettingAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getParamSettingList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await billDel(params)
          await this.getParamSettingList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          name: '',
        }
        this.getParamSettingList()
      },
      defaultValueFormat(val) {
        let str = ''
        this.checkedList.some((el) => {
          if (el.value === val) {
            str = el.label
            return true
          }
        })
        return str
      },
      typeFormat(val) {
        let str = ''
        this.typeListData.some((el) => {
          if (el.value === val) {
            str = el.label
            return true
          }
        })
        return str
      },
      codeFormat(val) {
        let str = ''
        this.parameterCodeOptions.some((el) => {
          if (el.name === val) {
            str = el.label
            return true
          }
        })
        return str
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }

  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-card.is-always-shadow {
    box-shadow: none !important;
  }

  .box-card.el-card {
    border: none !important;
  }
</style>
